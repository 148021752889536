import React from 'react';
import { CircularProgress, Dialog, Fade, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import styles from './styles';

// Transition Component allows us to animate the entry of the modal itself
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade in ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

const withLoadingModal = (Component) => (props) => {
    // Allow client component to control modal visibility and loading text
    const [modalIsOpen, setModalOpen] = React.useState(false);
    const [loadingText, setLoadingText] = React.useState('Processing');

    // Use a more imperative approve to styling here to reduce amount of HOC wrappers
    const classes = useStyles();

    // Toggles the visibility state of the modal based on previous state
    const toggleModal = () => {
        setModalOpen((prevState) => !prevState);
    };

    return (
        <React.Fragment>
            <Component
                toggleLoadingModal={toggleModal}
                setLoadingText={setLoadingText}
                modalIsOpen={modalIsOpen}
                {...props}
            />
            <Dialog
                position={'relative'}
                PaperComponent={'div'}
                open={modalIsOpen}
                onClose={toggleModal}
                TransitionComponent={Transition}
                keepMounted
                disableBackdropClick
                disableEscapeKeyDown
            >
                <div className={classes.loadingModal}>
                    <CircularProgress size={'60px'} className={classes.whiteText} />
                    <Typography
                        variant={'subtitle1'}
                        className={clsx(classes.whiteText, classes.textPadding)}
                    >
                        {loadingText}
                    </Typography>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default withLoadingModal;
