import React from 'react';
import { Elements } from 'react-stripe-elements';
import { Stepper } from '../src/components';
import withNavWrapper from '../src/hocs/NavWrapper';
import withAuth from '../src/hocs/withAuth/withAuth';
import CheckoutForm from '../src/layouts/CheckoutForm';
import SubmissionTypeForm from '../src/layouts/SubmissionTypeForm';
import IntroForm from '../src/layouts/IntroForm';
import CreditsForm from '../src/layouts/CreditsForm';
import ContentForm from '../src/layouts/ContentForm';
import { withApollo } from '../src/lib/apollo';

const Index = (props) => {
    const { currentUser, hydrateUser } = props;

    const preferred = currentUser && currentUser.profile.preferred;

    // Current submission object
    const [submission, setSubmission] = React.useState({
        user: {
            email: '',
            username: '',
            firstName: '',
            lastName: '',
            password: '',
        },
        submission: {
            submissionType: null,
            contentType: 'Video',
            contentUrl: '',
            contentTitle: '',
            contentImage: null,
            contentDescription: '',
        },
        credits: [],
        checkout: {
            paymentType: 'Stripe',
            paymentMethod: {},
            total: 0,
        },
    });

    // Handlers for active submission and visited steps
    const [submissionStep, setSubmissionStep] = React.useState(0);

    /**
     * Objects containing step renderer functions and dynamic labels
     */
    const introForm = {
        id: 'introForm',
        renderForm: () => {
            return (
                <IntroForm
                    currentUser={currentUser}
                    user={submission.user}
                    nextStep={nextStep}
                    setField={setField}
                    hasBacktracked={visitedSteps[introForm.id] || false}
                    hydrateUser={hydrateUser}
                />
            );
        },
        label: currentUser
            ? `Welcome ${preferred ? 'Preferred User!' : ''}`
            : 'Your Info',
    };

    const submissionTypeForm = {
        id: 'submissionTypeForm',
        renderForm: () => {
            return (
                <SubmissionTypeForm
                    submission={submission.submission}
                    nextStep={nextStep}
                    setField={setField}
                    handleBackPress={goBackToStep}
                />
            );
        },
        label: 'Submission Type',
    };

    const contentForm = {
        id: 'contentForm',
        renderForm: () => {
            return (
                <ContentForm
                    submission={submission.submission}
                    nextStep={nextStep}
                    setField={setField}
                    hasBacktracked={visitedSteps[contentForm.id] || false}
                    handleBackPress={goBackToStep}
                />
            );
        },
        label: 'Content',
    };

    const creditForm = {
        id: 'creditForm',
        renderForm: () => {
            return (
                <CreditsForm
                    initialCredits={submission.credits}
                    user={submission.user}
                    currentUser={currentUser}
                    nextStep={nextStep}
                    setField={setField}
                    handleBackPress={goBackToStep}
                />
            );
        },
        label: 'Credits',
    };

    const reviewAndPaymentForm = {
        id: 'reviewAndPaymentForm',
        renderForm: () => {
            return (
                <Elements>
                    <CheckoutForm
                        currentUser={currentUser}
                        user={submission.user}
                        submission={submission.submission}
                        credits={submission.credits}
                        checkout={submission.checkout}
                        nextStep={nextStep}
                        handleBackPress={goBackToStep}
                    />
                </Elements>
            );
        },
        label: preferred ? 'Review' : 'Payment',
    };

    /**
     * Set the order in which steps will be displayed to the end-user. This is a based on whether
     * the user is preferred or not.
     */
    const preferredUserSteps = [introForm, contentForm, creditForm, reviewAndPaymentForm];
    const standardUserSteps = [
        introForm,
        submissionTypeForm,
        contentForm,
        creditForm,
        reviewAndPaymentForm,
    ];
    const steps = preferred ? preferredUserSteps : standardUserSteps;

    // Map through each step and pull a mapping of label -> visited
    const initVisited = {};
    steps.forEach((step) => {
        initVisited[step.id] = false;
    });
    const [visitedSteps, setVisitedSteps] = React.useState(initVisited);

    /**
     * Sets a specific field within the submission data object
     */
    const setField = (field, data) => {
        setSubmission((prevSubmissionData) => ({
            ...prevSubmissionData,
            [field]: data,
        }));
    };

    /**
     * Handler for moving to the next step and marking the currently completed step
     * as 'visited'
     */
    const nextStep = () => {
        setSubmissionStep((prevStep) => prevStep + 1);
        setVisitedSteps((prevVistedSteps) => ({
            ...prevVistedSteps,
            [steps[submissionStep].id]: true,
        }));
    };

    /**
     * Handler for backtracking within the the form
     */
    const goBackToStep = () => {
        setSubmissionStep((prevSubmissionStep) => prevSubmissionStep - 1);
    };

    /**
     * Handler for rendering the proper submission step based on the active step.
     * Here active step is passed by the <Stepper /> component
     */
    const renderStep = (activeStep) => {
        return steps[activeStep].renderForm();
    };

    return (
        <Stepper
            onBackPress={() => goBackToStep()}
            renderStepContent={renderStep}
            stepLabels={steps.map((step) => step.label)}
            activeStep={submissionStep}
        />
    );
};

const DataProvidedComponent = withApollo(Index);
const NavWrappedComponent = withNavWrapper(DataProvidedComponent);
export default withAuth(NavWrappedComponent);
