const styles = (theme) => ({
    // Root
    formRoot: {
        width: '100%',
    },
    dialogueContentContainer: {
        margin: 0,
        padding: 0,
        marginTop: '60px',
        [theme.breakpoints.up(799)]: {
            marginTop: '0px',
        },
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
    cardContentContainer: {
        margin: 0,
    },

    loadingContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    // App bar
    appbar: {
        height: '60px',
        padding: 0,
    },
    dialogActionsContainer: {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    disablePaperMargins: {
        margin: 0,
        padding: 0,
        //minHeight: '95vh',
        overflowY: 'hidden',
        width: '100%',
        maxWidth: '800px',
    },
    appBarToolbar: {
        height: '100%',
        backgroundColor: theme.palette.branding.elevator_dark,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
    },
    dividerContainer: {
        padding: theme.spacing(2, 0),
        width: '100%',
        //border: '3px solid pink',
    },
    dividerSep: {
        width: '48%',
    },
    dividerFull: {
        width: '100%',
    },
    dividerText: {
        padding: theme.spacing(0, 1),
        color: theme.palette.divider,
    },

    // Credit Type Selection
    chipListContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    chipContainer: {
        paddingRight: theme.spacing(1),
    },
    checkmark: {
        color: theme.palette.common.white,
    },

    // Text Fields
    socialIconContainer: {
        padding: theme.spacing(0, 2, 0, 0),
        height: '80px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textFieldContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },

    // Profile claim
    thisIsMeCheckBoxContainer: {
        paddingBottom: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    checkbox: {
        marginBottom: theme.spacing(4),
    },
    checkboxFormControl: {
        marginRight: 0,
    },

    // Social Media
    linkContainer: {
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        /* Instead use this non-standard one: */
        wordBreak: 'break-all',
    },
    link: {
        color: theme.palette.primary.main,
    },
    linkIcon: {
        color: 'white',
    },
    outlinedButton: {
        border: '2px solid black',
        color: 'black',
    },
});

export default styles;
