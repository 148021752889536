const styles = (theme) => ({
    textFieldContainer: {
        width: '100%',
    },
    linkContainer: {
        paddingTop: theme.spacing(2),
    },
    link: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    thisIsNotMeContainer: {
        paddingTop: theme.spacing(3),
    },
    formRoot: {
        width: '100%',
    },
    continueButton: {
        maxWidth: '500px',
    },
    mySubmissionsLink: {
        fontWeight: 'bold',
    },
});

export default styles;
