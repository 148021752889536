const styles = (theme) => ({
    // Products Picker
    productsContainer: {
        padding: theme.spacing(1),
    },
    divider: {
        width: '1px',
        height: '70px',
    },
    priceContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    priceText: {
        fontSize: theme.typography.pxToRem(40),
    },
    onSaleText: {
        color: theme.palette.common.fadedRed,
    },
    saleInfoContainer: {
        paddingTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    listingPriceRegular: {
        textDecoration: 'line-through',
    },

    optionSelectorContainer: {
        width: '100%',
    },

    // Product Description
    productDescriptionContainer: {
        padding: theme.spacing(1, 1, 0, 1),
        width: '100%',
    },
    msgContainer: {
        height: '50vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    buttonSpacer: {
        width: theme.spacing(2),
    },
});

export default styles;
