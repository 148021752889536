import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { withStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import { object as yupObject, string } from 'yup';
import { PageTitleDescription, ScreenContainer, Link } from '../../components';
import CREDIT_TYPES from '../../config/CreditTypes';
import { withApollo } from '../../lib/apollo';
import { signOut } from '../../lib/auth';
import { EMAIL_EXISTS } from '../../lib/queries/users';
import ContinueAsCTA from './ContinueAsCTA';
import UserInfoForm from './UserInfoForm';
import { NAVIGATION_ROUTES } from '../../config/NavigationRoutes';
import PropTypes from 'prop-types';
import styles from './styles';
import { Typography } from '@material-ui/core';

const IntroForm = (props) => {
    const {
        classes,
        nextStep,
        setField,
        currentUser,
        user,
        hasBacktracked,
        enqueueSnackbar,
        hydrateUser,
    } = props;

    React.useEffect(() => {
        window && window.scrollTo(0, 0);
    }, []);

    const apolloClient = useApolloClient();

    const checkUniqueness = async (args) => {
        const { data, errors } = await apolloClient.query({
            query: EMAIL_EXISTS,
            variables: args,
            fetchPolicy: 'network-only',
        });

        if (errors)
            return {
                emailUsed: true,
            };
        else if (data) return data;
    };

    /**
     * Basic Form Validation Schema. This Yup object is used by Formik to
     * validate all fields in the form as well as provide adequate help text
     * when necessary.
     */
    const validationSchema = yupObject({
        email: string()
            .email('Please enter a valid email address')
            .test('noWhiteSpace', 'Emails cannot contain spaces', (value) => {
                return !/\s/.test(value);
            })
            .required('Email is required'),
        firstName: string().required('This field is required'),
        lastName: string().required('This field is required'),
    });

    // Base form defaults used my formik. These fields are NOT UPDATED IN STATE.
    // these fields are accessible via the onSubmit function handler for the from.
    const initialValues = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
    };

    const handleContinueAsCurrentUser = () => {
        const { profile } = currentUser;
        setField('credits', [
            {
                type: CREDIT_TYPES.ARTIST,
                name: `${profile.firstName} ${profile.lastName}`,
                email: profile.email,
                isSubmitter: true,
                socialMedia: profile.socialMedia,
            },
        ]);

        nextStep();
    };

    const handleSignoutClick = async () => {
        await signOut();
        await apolloClient.resetStore();
        enqueueSnackbar(`You've been signed out`, {
            variant: 'info',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
        });
        await hydrateUser();
    };

    const handleSubmit = async (values, actions) => {
        const { emailUsed } = await checkUniqueness({
            email: values.email,
        });

        if (emailUsed) {
            actions.setFieldError('email', 'This email is already in use');

            return;
        }

        setField('user', {
            ...values,
        });

        setField('credits', [
            {
                type: CREDIT_TYPES.ARTIST,
                name: `${values.firstName} ${values.lastName}`,
                isSubmitter: true,
                socialMedia: [],
            },
        ]);

        nextStep();
    };

    return (
        <ScreenContainer>
            <PageTitleDescription
                pageTitle={'SUBMISSIONS'}
                description={
                    <div>
                        <Typography variant={'body1'} component={'span'}>
                            ELEVATOR accepts Music, Videos, Written Articles and Photo
                            Sets for Submissions & Sponsored Posts. We also accept
                            Products/Services for Sponsored Posts. You will receive a
                            notification email once your submission has been reviewed.
                            Your submission status is also available under
                            <Link
                                href={NAVIGATION_ROUTES.ACCOUNT.SUBMISSIONS.route}
                                className={classes.mySubmissionsLink}
                            >
                                {` My Submissions`}
                            </Link>
                            . We review most submissions within 72 hours but may take up
                            to 7 days.
                        </Typography>
                    </div>
                }
            >
                {!currentUser && (
                    <div className={classes.linkContainer}>
                        <a href={NAVIGATION_ROUTES.SIGN_IN.uri} className={classes.link}>
                            Already have an account? <u>Sign In</u>
                        </a>
                    </div>
                )}
            </PageTitleDescription>
            {currentUser ? (
                <ContinueAsCTA
                    handleContinueAsCurrentUser={handleContinueAsCurrentUser}
                    handleSignoutClick={handleSignoutClick}
                    currentUser={currentUser}
                />
            ) : (
                <Formik
                    children={(props) => <UserInfoForm {...props} />}
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    // If the user has been on this form before (i.e., they pressed back)
                    // Form should not be disabled
                    isInitialValid={hasBacktracked}
                />
            )}
        </ScreenContainer>
    );
};

IntroForm.propTypes = {
    classes: PropTypes.object.isRequired,
    nextStep: PropTypes.func.isRequired,
    setField: PropTypes.func.isRequired,
    user: PropTypes.shape({
        email: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
    }).isRequired,
    hasBacktracked: PropTypes.bool.isRequired,
};

const StyledIntroForm = withStyles(styles)(IntroForm);
const DataProvidedComponent = withApollo(StyledIntroForm);

export default withSnackbar(DataProvidedComponent);
