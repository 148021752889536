const styles = (theme) => ({
    disablePaperMargins: {
        padding: 0,
        margins: 0,
    },
    loadingModal: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    whiteText: {
        color: theme.palette.common.white,
    },
    textPadding: {
        paddingTop: theme.spacing(2),
    },
});

export default styles;
