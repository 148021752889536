const styles = (theme) => ({
    root: {
        width: '100%',
    },
    screenContentMargin: {
        padding: theme.spacing(0, 2, 1, 2),
    },
    buttonSpacer: {
        width: theme.spacing(2),
    },
});

export default styles;
