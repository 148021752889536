import gql from 'graphql-tag';

export const SUBMISSION_TYPE_OPTIONS = gql`
    query {
        submissionTypeOptions {
            value
            productDescription
            price
            onSale
        }
    }
`;

export const MY_SUBMISSIONS = gql`
    query {
        mySubmissions {
            orderNumber
            contentTitle
            contentUrl
            contentDescription
            status
            flagged
            createdAt
            submissionType
            order {
                amountPaid
            }
            contentImage {
                sourceUrl
                sourceSet
            }
            post {
                title
                slug
            }
        }
    }
`;

export const MY_SUBMISSION_BY_ORDER_NUMBER = gql`
    query mySubmissionByOrderNumber($orderNumber: String!) {
        mySubmissionByOrderNumber(orderNumber: $orderNumber) {
            orderNumber
            contentTitle
            contentUrl
            contentDescription
            status
            flagged
            createdAt
            submissionType
            terms
            order {
                paymentType
                amountPaid
                ccLast4
            }
            contentImage {
                sourceUrl
                sourceSet
            }
            post {
                title
                slug
            }
            credits {
                creditId
                creditType {
                    typeName
                }
                name
                user {
                    userId
                    preferredUsername
                }
                socialMedia {
                    type
                    link
                }
            }
        }
    }
`;
