const styles = (theme) => ({
    textFieldContainer: {
        width: '100%',
    },
    imageUploadsDisclaimer: {
        paddingTop: theme.spacing(2),
    },
    errorText: {
        color: 'red',
    },
    imageContainer: {
        paddingTop: theme.spacing(1),
    },
    formRoot: {
        width: '100%',
    },
    buttonSpacer: {
        width: theme.spacing(2),
    },
    fileName: {
        paddingRight: theme.spacing(1),
    },
    contentDescriptionHelperText: {
        maxWidth: '80%',
    },
});

export default styles;
