import SubmissionTypeForm from './SubmissionTypeForm';
import { withApollo } from '../../lib/apollo';
import { useQuery } from '@apollo/react-hooks';
import { SUBMISSION_TYPE_OPTIONS } from '../../lib/queries/submissions';

const WiredSubmissionTypeForm = (props) => {
    const { data, loading, networkStatus, error, refetch } = useQuery(
        SUBMISSION_TYPE_OPTIONS,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only',
        },
    );

    React.useEffect(() => {
        window && window.scrollTo(0, 0);
    }, []);

    const typeOptions =
        data && data.submissionTypeOptions ? data.submissionTypeOptions : null;
    const isLoading = loading || networkStatus === 4;

    return (
        <SubmissionTypeForm
            submissionTypes={typeOptions}
            loading={isLoading}
            error={error}
            refetch={refetch}
            {...props}
        />
    );
};

export default withApollo(WiredSubmissionTypeForm);
