import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import {
    CardSectionHeader,
    GridContainer,
    GridItem,
    ActionAreaContainer,
} from '../../components';
import PropTypes from 'prop-types';
import styles from './styles';

const UserInfoForm = (props) => {
    const {
        classes,
        handleSubmit,
        setFieldValue,
        handleChange,
        setFieldTouched,
        isValid,
        values,
        touched,
        errors,
    } = props;

    /**
     * Handler func for field changes in the formik form. Will set the new state value
     * as well a mark the field as "touched" for validation purposes.
     */
    const handleInputChange = (fieldName, event, imperativeValue = null) => {
        // if value is being set imperatively, use the standard React event.target.value scheme.
        if (imperativeValue) {
            setFieldValue(fieldName, imperativeValue);
        } else {
            handleChange(event);
        }
        setFieldTouched(fieldName, true);
    };

    return (
        <form className={classes.formRoot} onSubmit={(event) => event.preventDefault()}>
            <Paper>
                <CardSectionHeader sectionTitle={'Your Info'} />
                <GridContainer direction={'column'} spacing={3}>
                    {/*First Name*/}
                    <GridItem className={classes.textFieldContainer}>
                        <TextField
                            id={'firstName'}
                            label={'First Name'}
                            autoComplete={'given-name'}
                            fullWidth
                            required
                            value={values.firstName}
                            onChange={(event) => handleInputChange('firstName', event)}
                            error={touched.firstName && Boolean(errors.firstName)}
                            helperText={touched.firstName ? errors.firstName : ''}
                        />
                    </GridItem>

                    {/*Last Name*/}
                    <GridItem className={classes.textFieldContainer}>
                        <TextField
                            id={'lastName'}
                            label={'Last Name'}
                            autoComplete={'family-name'}
                            fullWidth
                            required
                            value={values.lastName}
                            onChange={(event) => handleInputChange('lastName', event)}
                            error={touched.lastName && Boolean(errors.lastName)}
                            helperText={touched.lastName ? errors.lastName : ''}
                        />
                    </GridItem>

                    {/*Email*/}
                    <GridItem className={classes.textFieldContainer}>
                        <TextField
                            id={'email'}
                            label={'Email'}
                            autoComplete={'email'}
                            fullWidth
                            required
                            value={values.email}
                            onChange={(event) => handleInputChange('email', event)}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email ? errors.email : ''}
                        />
                    </GridItem>
                </GridContainer>
            </Paper>

            <ActionAreaContainer direction={'column'}>
                <Button
                    type={'submit'}
                    disabled={!isValid}
                    variant={'contained'}
                    fullWidth
                    className={classes.continueButton}
                    size={'large'}
                    color={'primary'}
                    onClick={handleSubmit}
                >
                    Next
                </Button>
            </ActionAreaContainer>
        </form>
    );
};

UserInfoForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserInfoForm);
