import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { ActionAreaContainer } from '../../components';
import PropTypes from 'prop-types';
import styles from './styles';

const ContinueAsCTA = (props) => {
    const {
        classes,
        currentUser,
        handleSignoutClick,
        handleContinueAsCurrentUser,
    } = props;

    return (
        <div className={classes.formRoot} onSubmit={(event) => event.preventDefault()}>
            <ActionAreaContainer direction={'column'}>
                <Button
                    variant={'contained'}
                    fullWidth
                    className={classes.continueButton}
                    size={'large'}
                    color={'primary'}
                    onClick={handleContinueAsCurrentUser}
                >
                    Continue as {currentUser.profile.preferredUsername}
                </Button>
                <div className={classes.thisIsNotMeContainer}>
                    <Button
                        variant={'text'}
                        className={classes.continueButton}
                        size={'large'}
                        color={'primary'}
                        onClick={handleSignoutClick}
                    >
                        This is not me
                    </Button>
                </div>
            </ActionAreaContainer>
        </div>
    );
};

ContinueAsCTA.propTypes = {
    classes: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    handleSignoutClick: PropTypes.func.isRequired,
    handleContinueAsCurrentUser: PropTypes.func.isRequired,
};

export default withStyles(styles)(ContinueAsCTA);
