import { Paper, Hidden, useMediaQuery, Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles, useTheme } from '@material-ui/styles';
import {
    CardSectionHeader,
    GridContainer,
    GridItem,
    ActionAreaContainer,
    ImageUploader,
} from '../../components';
import PropTypes from 'prop-types';
import styles from './styles';

const MAX_DESCRIPTION_CHAR_COUNT = 5000;

const SubmissionContentForm = (props) => {
    const {
        classes,
        handleSubmit,
        setFieldValue,
        handleChange,
        setFieldTouched,
        isValid,
        values,
        touched,
        errors,
        handleBackPress,
        submissionType,
    } = props;

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const descriptionField = React.useRef(null);
    const fileInputField = React.useRef(null);

    /**
     * Handler func for field changes in the formik form. Will set the new state value
     * as well a mark the field as "touched" for validation purposes.
     */
    const handleInputChange = (fieldName, event, imperativeValue = undefined) => {
        // if value is being set imperatively, use the standard React event.target.value scheme.
        if (imperativeValue !== undefined) {
            setFieldValue(fieldName, imperativeValue);
        } else {
            handleChange(event);
        }
        setFieldTouched(fieldName, true);

        if (fieldName === 'contentImage') {
            focusDescriptionField();
        }
    };

    const focusDescriptionField = () => {
        if (values.contentDescription.length < 1) {
            setTimeout(() => {
                descriptionField.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
                descriptionField && descriptionField.current.focus();
            }, 400);
        }
    };

    return (
        <form className={classes.formRoot} onSubmit={(event) => event.preventDefault()}>
            <Paper>
                <GridContainer direction={'column'} spacing={3}>
                    {/*Content URL*/}
                    <GridItem className={classes.textFieldContainer}>
                        <TextField
                            id={'contentUrl'}
                            label={'Content Link'}
                            fullWidth
                            required
                            value={values.contentUrl}
                            onChange={(event) => handleInputChange('contentUrl', event)}
                            error={touched.contentUrl && Boolean(errors.contentUrl)}
                            helperText={
                                touched.contentUrl && Boolean(errors.contentUrl)
                                    ? errors.contentUrl
                                    : 'Link to song, video or photoset. Private links will be declined.'
                            }
                        />
                    </GridItem>

                    {/*Content Title*/}
                    <GridItem className={classes.textFieldContainer}>
                        <TextField
                            id={'contentTitle'}
                            label={'Submission Title'}
                            fullWidth
                            required
                            value={values.contentTitle}
                            onChange={(event) => handleInputChange('contentTitle', event)}
                            error={touched.contentTitle && Boolean(errors.contentTitle)}
                            helperText={touched.contentTitle ? errors.contentTitle : ''}
                        />
                    </GridItem>

                    {/*Image*/}
                    <GridItem className={classes.textFieldContainer}>
                        <ImageUploader
                            id={'contentImage'}
                            error={touched.contentImage && Boolean(errors.contentImage)}
                            helperText={
                                touched.contentImage
                                    ? errors.contentImage
                                    : 'Please provide 1080x1080px artwork for youtube uploads'
                            }
                            inputProps={{
                                id: 'contentImage',
                                ref: fileInputField,
                                onChange: (event) =>
                                    handleInputChange(
                                        'contentImage',
                                        event,
                                        event.target.files[0],
                                    ),
                            }}
                            onRemoveImage={() => {
                                handleInputChange('contentImage', null, null);
                                // Using the ref value to file input, clear its value attr
                                fileInputField.current.value = '';
                            }}
                            file={values.contentImage}
                        />
                    </GridItem>

                    {/*Description*/}
                    <GridItem className={classes.textFieldContainer}>
                        <TextField
                            inputRef={descriptionField}
                            id={'contentDescription'}
                            label={'Description'}
                            placeholder={
                                submissionType && submissionType.value === 'Paid'
                                    ? `What you write here is what we post`
                                    : 'Content description'
                            }
                            multiline
                            rows={10}
                            fullWidth
                            required
                            value={values.contentDescription}
                            onChange={(event) =>
                                handleInputChange('contentDescription', event)
                            }
                            inputProps={{
                                maxLength: MAX_DESCRIPTION_CHAR_COUNT,
                            }}
                            error={
                                touched.contentDescription &&
                                Boolean(errors.contentDescription)
                            }
                            helperText={
                                <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    justifyContent={'space-between'}
                                    component={'span'}
                                >
                                    <Typography
                                        variant={'caption'}
                                        color={
                                            touched.contentDescription &&
                                            Boolean(errors.contentDescription)
                                                ? 'error'
                                                : 'initial'
                                        }
                                        className={classes.contentDescriptionHelperText}
                                    >
                                        {touched.contentDescription &&
                                        Boolean(errors.contentDescription)
                                            ? errors.contentDescription
                                            : 'Tell us about your content. DO NOT include social links, you can add them in the next step when adding creator credits.'}
                                    </Typography>
                                    <Typography variant={'caption'} color={'initial'}>
                                        {values.contentDescription.length} /{' '}
                                        {MAX_DESCRIPTION_CHAR_COUNT}
                                    </Typography>
                                </Box>
                            }
                        />
                    </GridItem>
                </GridContainer>
            </Paper>

            <ActionAreaContainer>
                <Hidden smDown>
                    <Button
                        variant={'contained'}
                        size={'large'}
                        color={'secondary'}
                        onClick={handleBackPress}
                    >
                        Back
                    </Button>
                    <div className={classes.buttonSpacer} />
                </Hidden>
                <Button
                    type={'submit'}
                    disabled={!isValid}
                    variant={'contained'}
                    fullWidth={mobile}
                    size={'large'}
                    color={'primary'}
                    onClick={handleSubmit}
                >
                    Next
                </Button>
            </ActionAreaContainer>
        </form>
    );
};

SubmissionContentForm.propTypes = {
    classes: PropTypes.object.isRequired,
    handleBackPress: PropTypes.func.isRequired,
    submissionType: PropTypes.object,
};

export default withStyles(styles)(SubmissionContentForm);
