import React from 'react';
import { TextField, Typography, withStyles } from '@material-ui/core';
import { CardElement } from 'react-stripe-elements';
import { GridContainer, GridItem, LocationAutocomplete } from '../../components';
import paletteConfig from '../../theme/palette';
import styles from './styles';

const CreditCardForm = (props) => {
    const { classes, formikBag, handleInputChange } = props;
    const { values, errors, touched } = formikBag;

    const [creditInputActive, setCreditInputActive] = React.useState(false);

    const createCardFormOptions = () => {
        return {
            style: {
                base: {
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '16px',
                    '&$focused': {
                        color: paletteConfig.palette.common.black,
                    },
                    '::placeholder': {
                        color: '#ACACAC',
                        fontWeight: '300',
                    },
                    fontWeight: '300',
                    iconColor: '#000000',
                },
                invalid: {
                    color: paletteConfig.palette.common.fadedRed,
                },
                complete: {
                    color: paletteConfig.palette.common.fadedGreen,
                },
            },
        };
    };

    const getLabelClassName = () => {
        if (touched.cardInfo && Boolean(errors.cardInfo)) {
            return classes.errorText;
        } else if (creditInputActive) {
            return classes.activeText;
        } else {
            return classes.defaultText;
        }
    };

    return (
        <GridContainer direction={'column'} spacing={3}>
            <GridItem className={classes.textField}>
                <Typography variant={'subtitle2'} className={getLabelClassName()}>
                    Card *
                </Typography>
                <div className={classes.cardInputContainer}>
                    <CardElement
                        id={'cardInfo'}
                        name={'cardInfo'}
                        onChange={(change) =>
                            handleInputChange(formikBag, 'cardInfo', null, change)
                        }
                        onFocus={() => setCreditInputActive((prev) => !prev)}
                        onBlur={() => setCreditInputActive((prev) => !prev)}
                        {...createCardFormOptions()}
                    />
                </div>
                <Typography variant={'caption'} color={'error'}>
                    {touched.cardInfo ? errors.cardInfo : ''}
                </Typography>
            </GridItem>
            <GridItem className={classes.textField}>
                <TextField
                    id={'nameOnCard'}
                    name={'nameOnCard'}
                    label={'Name on card'}
                    autoComplete={'name'}
                    fullWidth
                    required
                    value={values.nameOnCard}
                    onChange={(event) =>
                        handleInputChange(formikBag, 'nameOnCard', event)
                    }
                    error={touched.nameOnCard && Boolean(errors.nameOnCard)}
                    helperText={touched.nameOnCard ? errors.nameOnCard : ''}
                />
            </GridItem>
            <GridItem className={classes.textField}>
                <TextField
                    id={'address'}
                    name={'address'}
                    label={'Address'}
                    required
                    placeholder={'123 Fake St.'}
                    autoComplete={'street-address'}
                    value={values.address}
                    fullWidth
                    onChange={(event) => handleInputChange(formikBag, 'address', event)}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address ? errors.address : ''}
                />
            </GridItem>
            <GridItem className={classes.textField}>
                <GridContainer direction={'row'} spacing={3}>
                    <GridItem xs className={classes.textField}>
                        <TextField
                            id={'city'}
                            name={'city'}
                            label={'City'}
                            fullWidth
                            required
                            value={values.city}
                            placeholder={'City'}
                            autoComplete={'address-level2'}
                            onChange={(event) =>
                                handleInputChange(formikBag, 'city', event)
                            }
                            error={touched.city && Boolean(errors.city)}
                            helperText={touched.city ? errors.city : ''}
                        />
                    </GridItem>
                    <GridItem xs className={classes.textField}>
                        <LocationAutocomplete
                            type={'state'}
                            inputProps={{
                                id: 'state',
                                name: 'state',
                                label: 'State',
                                placeholder: 'Illinois',
                                required: true,
                                fullWidth: true,
                                error: null,
                                helperText: null,
                                value: values.state,
                            }}
                            onChange={(input) =>
                                handleInputChange(formikBag, 'state', null, input)
                            }
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
            <GridItem className={classes.textField}>
                <LocationAutocomplete
                    type={'country'}
                    inputProps={{
                        id: 'country',
                        name: 'country',
                        label: 'Country',
                        placeholder: 'United States',
                        required: true,
                        fullWidth: true,
                        error: null,
                        helperText: null,
                        value: values.country,
                    }}
                    onChange={(input) =>
                        handleInputChange(formikBag, 'country', null, input)
                    }
                />
            </GridItem>
        </GridContainer>
    );
};

CreditCardForm.propTypes = {};

export default withStyles(styles)(CreditCardForm);
