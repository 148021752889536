import { useQuery } from '@apollo/react-hooks';
import {
    AppBar,
    Box,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Slide,
    useMediaQuery,
    Collapse,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTheme, withStyles } from '@material-ui/styles';
import {
    Check,
    Close,
    Instagram,
    LinkVariantOff,
    Soundcloud,
    Twitter,
    Youtube,
    Facebook,
    ChevronDown,
    ChevronUp,
} from 'mdi-material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { CardSectionHeader, UsernameSearchInput, Callout } from '../../../components';
import { CREDIT_TYPES } from '../../../lib/queries/creditTypes';
import styles from './styles';
import {
    splitSocialMediaLink,
    getDefaultSocialMediaPrefixes,
    normalizeLink,
} from '../../../lib/util';
import isURL from 'validator/lib/isURL';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddCreditModal = (props) => {
    const {
        classes,
        isOpen,
        closeModal,
        currentUser,

        handleSubmit,
        setFieldValue,
        handleChange,
        setFieldTouched,
        isValid,
        values,
        touched,
        errors,
        resetForm,
        initialValues,
        onCancel,
        socialPrefixes,
    } = props;

    const { loading, data, error } = useQuery(CREDIT_TYPES, {
        notifyOnNetworkStatusChange: true,
    });

    // Keeps track of whether the modal is being loaded in a read only state
    // or writable. Here, readonly state applies to where a user searched for an existing
    // Elevator user and links it to their submission
    const [manualEntry, setManualEntry] = React.useState(true);

    // Keeps track of the search input text
    const [textEntry, setTextEntry] = React.useState('');

    /**
     * NOTE: The following fuction is a workaround for state-management in Formik.
     * Currently, the initialValues prop should be tied to state (allowing us to
     * dynamically render an edit or new mode.) Unfortuntaly, the deep comparisons
     * on initialValues is not sufficient for the component to re-render. The workaround
     * here is to have the form be imperatively reset when the Dialogue enters.
     *
     * More info:
     * https://github.com/jaredpalmer/formik/issues/811
     * https://github.com/jaredpalmer/formik/issues/1808
     *
     */
    const onEnter = () => {
        // Reset form with initial values received via props from parent.
        resetForm(initialValues);

        // If the use we are loading was autolinked, we load the form in readonly state
        const isNewForm = !initialValues.autoLinked;
        if (isNewForm) {
            setManualEntry(true);
        } else {
            setManualEntry(false);
        }
    };

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(799));

    const [basicInfoCollapsed, setBasicInfoCollapsed] = React.useState(false);
    const facebookFieldRef = React.useRef(null);
    const dialogContentRef = React.useRef(null);

    /**
     * Handler func for field changes in the formik form. Will set the new state value
     * as well a mark the field as "touched" for validation purposes.
     */
    const handleInputChange = (fieldName, event, imperativeValue = null) => {
        // if value is being set imperatively, use the standard React event.target.value scheme.
        if (imperativeValue) {
            setFieldValue(fieldName, imperativeValue);
        } else {
            handleChange(event);
        }
        setFieldTouched(fieldName, true);
    };

    /**
     * Handler func for when a user selects an option from a auto-suggested list of
     * users
     */
    const onUserSelected = (user) => {
        // Reset any active fields first
        resetForm({
            name: '',
            email: '',
            type: values.type,
            isSubmitter: false,
            instagram: '',
            youtube: '',
            soundcloud: '',
            twitter: '',
            facebook: '',
            autoLinked: false,
        });

        // Auto-fill social media based on user
        user.socialMedia.forEach((value) => {
            const { type, link } = value;

            const [normalizedPrefix, normalizedUsername] = splitSocialMediaLink(link);

            socialPrefixes[type] = normalizedPrefix;

            // Because the user was auto-linked, we display the whole link
            handleInputChange(type, null, link);
        });
        // Add name as preferred user name
        handleInputChange('name', null, user.preferredUsername);

        // Clear search input
        setTextEntry('');

        // Signal to parent component that this user was auto-linked
        handleInputChange('autoLinked', null, true);

        // Add users email (won't be displayed)
        handleInputChange('email', null, user.email);

        let isCurrentUser = false;

        if (
            currentUser &&
            currentUser.profile.preferredUsername === user.preferredUsername
        )
            isCurrentUser = true;

        handleInputChange('isSubmitter', null, isCurrentUser);

        // Make form readonly
        setManualEntry(false);
    };

    /**
     * Handler function for when a user decides to clear (or 'unlink' rather) an auto-filled
     * form
     */
    const onClearUser = () => {
        resetForm({
            name: '',
            email: '',
            type: data.creditTypes[0].typeName,
            isSubmitter: initialValues.isSubmitter,
            instagram: '',
            youtube: '',
            soundcloud: '',
            twitter: '',
            facebook: '',
            autoLinked: false,
        });
        setManualEntry(true);
        setTextEntry('');
    };

    /*************
     * RENDERERS *
     *************/

    const renderChevronToggle = (toggleCb, collapsed) => {
        return (
            <IconButton onClick={() => toggleCb((prev) => !prev)}>
                {collapsed ? <ChevronDown /> : <ChevronUp />}
            </IconButton>
        );
    };

    const renderDialogueHeader = () => {
        return (
            <AppBar elevation={0} position={'fixed'} className={classes.appbar}>
                <div className={classes.appBarToolbar}>
                    <IconButton onClick={closeModal} color="inherit" aria-label="close">
                        <Close />
                    </IconButton>
                    <Typography variant="h6">Add Credit</Typography>
                </div>
            </AppBar>
        );
    };

    const renderDivider = (useText = false) => {
        return (
            <Box
                height={'48px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Divider
                    variant={'fullWidth'}
                    className={useText ? classes.dividerSep : classes.dividerFull}
                />
                {useText && (
                    <React.Fragment>
                        <Typography variant={'caption'} className={classes.dividerText}>
                            or
                        </Typography>
                        <Divider variant={'fullWidth'} className={classes.dividerSep} />
                    </React.Fragment>
                )}
            </Box>
        );
    };

    const renderCreditTypes = () => {
        const { creditTypes } = data;

        return (
            <Box>
                {renderSubSectionHeader(
                    'Role',
                    `What was this collaborator's role in your project?`,
                    false,
                )}
                <div className={classes.chipListContainer}>
                    {creditTypes.map((creditType, index) => {
                        const { typeName } = creditType;

                        const isActive = values.type === typeName;

                        const icon = isActive ? (
                            <Check className={classes.checkmark} />
                        ) : null;

                        return (
                            <div key={index} className={classes.chipContainer}>
                                <Chip
                                    id={'type'}
                                    icon={icon}
                                    label={typeName}
                                    size={'medium'}
                                    color={isActive ? 'primary' : 'secondary'}
                                    onClick={(event) =>
                                        handleInputChange('type', event, typeName)
                                    }
                                />
                            </div>
                        );
                    })}
                </div>
            </Box>
        );
    };

    const renderLinkedBadge = () => {
        const { instagram, youtube, soundcloud, twitter, facebook } = values;
        const socials = [
            ...(instagram && [instagram]),
            ...(youtube && [youtube]),
            ...(soundcloud && [soundcloud]),
            ...(twitter && [twitter]),
            ...(facebook && [facebook]),
        ];

        return (
            <Box paddingTop={2}>
                <Callout>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                    >
                        <Typography variant={'body2'}>
                            <b>{values.name}'s</b> info has been linked to your
                            submission! Tap 'Unlink' to start over or manually add a user
                            instead. If {values.name} has added social media links to
                            their profile, they can be found below:
                        </Typography>

                        {/**Social Media */}
                        <Box paddingTop={2} display={'flex'} flexDirection={'column'}>
                            {socials.length ? (
                                socials.map((link, index) => {
                                    if (!link) return null;
                                    return (
                                        <Box
                                            key={index}
                                            paddingBottom={2}
                                            className={classes.linkContainer}
                                        >
                                            <a
                                                href={normalizeLink(link)}
                                                target={'_blank'}
                                                className={classes.link}
                                            >
                                                {link}
                                            </a>
                                        </Box>
                                    );
                                })
                            ) : (
                                <Typography>(No social media)</Typography>
                            )}
                        </Box>
                    </Box>
                </Callout>

                {/**Unlink button*/}
                <Box paddingTop={2}>
                    <Button
                        onClick={onClearUser}
                        fullWidth
                        variant={'contained'}
                        color={'primary'}
                    >
                        <Box
                            paddingRight={1}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                        >
                            <LinkVariantOff
                                className={classes.linkIcon}
                                fontSize={'small'}
                            />
                        </Box>
                        Unlink
                    </Button>
                </Box>
            </Box>
        );
    };

    const renderCreditFieldBasicInfo = () => {
        return (
            <Paper className={classes.cardContentContainer}>
                <CardSectionHeader sectionTitle={'Basic Info'}>
                    {renderChevronToggle(setBasicInfoCollapsed, basicInfoCollapsed)}
                </CardSectionHeader>
                <Collapse in={!basicInfoCollapsed}>
                    <>
                        {renderSubSectionHeader(
                            `Let's get the basics`,
                            'Link your submission by searching for an Elevator user, or adding their information manually',
                        )}
                        <Box>
                            <Box>
                                <div className={classes.textFieldContainer}>
                                    <UsernameSearchInput
                                        id={'search'}
                                        typeVariant={'autoComplete'}
                                        label={'Search Elevator Username'}
                                        placeholder={'Username'}
                                        fullWidth
                                        onUserSelected={onUserSelected}
                                        textFieldInput={textEntry}
                                        setTextFieldInput={setTextEntry}
                                        disabled={!manualEntry}
                                    />
                                </div>
                            </Box>

                            {!manualEntry ? (
                                renderLinkedBadge()
                            ) : (
                                <React.Fragment>
                                    {renderDivider(true)}

                                    <Box paddingBottom={3}>
                                        <TextField
                                            id={'name'}
                                            required
                                            label={`${values.type}'s Name`}
                                            placeholder={'ex: Young Jasper'}
                                            value={values.name}
                                            fullWidth
                                            onChange={(event) =>
                                                handleInputChange('name', event)
                                            }
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name ? errors.name : ''}
                                        />
                                    </Box>
                                    <Box paddingBottom={3}>
                                        <TextField
                                            id={'email'}
                                            label={'Email'}
                                            value={values.email}
                                            fullWidth
                                            onChange={(event) =>
                                                handleInputChange('email', event)
                                            }
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email ? errors.email : ''}
                                        />
                                    </Box>
                                    <Box>{renderProfileClaimCheckbox()}</Box>
                                </React.Fragment>
                            )}
                        </Box>
                    </>
                </Collapse>
            </Paper>
        );
    };

    const renderProfileClaimCheckbox = () => {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        id={'isSubmitter'}
                        checked={values.isSubmitter}
                        onChange={(event) =>
                            handleInputChange('isSubmitter', event, event.target.checked)
                        }
                        color={'primary'}
                        inputProps={{
                            'aria-label': 'isSubmitter',
                        }}
                        className={classes.checkbox}
                    />
                }
                className={classes.checkboxFormControl}
                label={
                    <div>
                        <Typography gutterBottom={false} variant={'body2'}>
                            {'This is me'}
                        </Typography>
                        <Typography color={'textSecondary'} variant={'caption'}>
                            Link this credit information to my Elevator profile so other
                            users can credit me on future submissions.
                        </Typography>
                    </div>
                }
            />
        );
    };

    const renderSubSectionHeader = (
        title,
        description,
        gutter = true,
        warningText = null,
    ) => {
        return (
            <Box paddingBottom={3}>
                <Typography variant={'subtitle2'}>{title}</Typography>
                <Typography variant={'body2'} gutterBottom={gutter}>
                    {description}
                </Typography>
                {warningText}
            </Box>
        );
    };

    /**
     * Generates a real-time social media link preview based on user
     * input. if the input is null, return a null React Node.
     */
    const getSociaLinkPreview = (type, baseUrl, value) => {
        let qualifiedUrl;
        const emptyValue = !Boolean(value);

        // if the input value is not empty and is a type of url
        if (!emptyValue && isURL(value)) {
            const [normalizedPrefix] = splitSocialMediaLink(value);

            // update prefixes (becomes new template)
            socialPrefixes[type] = normalizedPrefix;

            // preview becomes value
            qualifiedUrl = value;
        } else {
            // input is only the username

            //baseUrl (prefix) can be null. update existing template
            if (!Boolean(baseUrl) || emptyValue) {
                // update prefixes (becomes new template)
                socialPrefixes[type] = getDefaultSocialMediaPrefixes(type);
            }

            qualifiedUrl = `${socialPrefixes[type]}${Boolean(value) ? value : '[none]'}`;
        }

        return (
            <Typography
                href={emptyValue ? null : normalizeLink(qualifiedUrl)}
                component={emptyValue ? 'span' : 'a'}
                variant={'caption'}
                color={emptyValue ? 'textSecondary' : 'primary'}
                target={'_blank'}
            >
                {normalizeLink(qualifiedUrl, false)}
            </Typography>
        );
    };

    const renderSocialMediaHandles = () => {
        return (
            <Box>
                {renderSubSectionHeader(
                    'Social Media Handles',
                    '@ your credits so we can tag them in our posts!',
                    false,
                    currentUser && values.isSubmitter ? (
                        <Typography variant={'caption'} color={'error'}>
                            Note: Editing your social media links here will update your
                            Elevator profile.
                        </Typography>
                    ) : null,
                )}
                <Box paddingBottom={2} className={classes.textFieldContainer}>
                    <div className={classes.socialIconContainer}>
                        <Facebook color={'disabled'} />
                    </div>
                    <TextField
                        id={'facebook'}
                        inputRef={facebookFieldRef}
                        disabled={!manualEntry}
                        label={'Facebook'}
                        value={values.facebook}
                        fullWidth
                        placeholder={!manualEntry ? '[none]' : 'username'}
                        onChange={(event) => handleInputChange('facebook', event)}
                        error={touched.facebook && Boolean(errors.facebook)}
                        helperText={
                            errors.facebook ||
                            getSociaLinkPreview(
                                'facebook',
                                socialPrefixes.facebook,
                                values.facebook,
                            )
                        }
                    />
                </Box>
                <Box paddingBottom={2} className={classes.textFieldContainer}>
                    <div className={classes.socialIconContainer}>
                        <Instagram color={'disabled'} />
                    </div>
                    <TextField
                        id={'instagram'}
                        disabled={!manualEntry}
                        fullWidth
                        placeholder={!manualEntry ? '[none]' : 'username'}
                        label={'Instagram'}
                        value={values.instagram}
                        onChange={(event) => handleInputChange('instagram', event)}
                        error={touched.instagram && Boolean(errors.instagram)}
                        helperText={
                            errors.instagram ||
                            getSociaLinkPreview(
                                'instagram',
                                socialPrefixes.instagram,
                                values.instagram,
                            )
                        }
                    />
                </Box>
                <Box paddingBottom={2} className={classes.textFieldContainer}>
                    <div className={classes.socialIconContainer}>
                        <Twitter color={'disabled'} />
                    </div>
                    <TextField
                        id={'twitter'}
                        disabled={!manualEntry}
                        label={'Twitter'}
                        value={values.twitter}
                        fullWidth
                        placeholder={!manualEntry ? '[none]' : 'handle'}
                        onChange={(event) => handleInputChange('twitter', event)}
                        error={touched.twitter && Boolean(errors.twitter)}
                        helperText={
                            errors.twitter ||
                            getSociaLinkPreview(
                                'twitter',
                                socialPrefixes.twitter,
                                values.twitter,
                            )
                        }
                    />
                </Box>
                <Box paddingBottom={2} className={classes.textFieldContainer}>
                    <div className={classes.socialIconContainer}>
                        <Soundcloud color={'disabled'} />
                    </div>
                    <TextField
                        id={'soundcloud'}
                        disabled={!manualEntry}
                        label={'SoundCloud'}
                        value={values.soundcloud}
                        placeholder={!manualEntry ? '[none]' : 'username'}
                        fullWidth
                        onChange={(event) => handleInputChange('soundcloud', event)}
                        error={touched.soundcloud && Boolean(errors.soundcloud)}
                        helperText={
                            errors.soundcloud ||
                            getSociaLinkPreview(
                                'soundcloud',
                                socialPrefixes.soundcloud,
                                values.soundcloud,
                            )
                        }
                    />
                </Box>
                <Box paddingBottom={2} className={classes.textFieldContainer}>
                    <div className={classes.socialIconContainer}>
                        <Youtube color={'disabled'} />
                    </div>
                    <TextField
                        id={'youtube'}
                        disabled={!manualEntry}
                        label={'YouTube'}
                        value={values.youtube}
                        placeholder={!manualEntry ? '[none]' : 'your_channel_id'}
                        fullWidth
                        onChange={(event) => handleInputChange('youtube', event)}
                        error={touched.youtube && Boolean(errors.youtube)}
                        helperText={
                            errors.youtube ||
                            getSociaLinkPreview(
                                'youtube',
                                socialPrefixes.youtube,
                                values.youtube,
                            )
                        }
                    />
                </Box>
            </Box>
        );
    };

    const renderAddCreditModal = () => {
        return (
            <form onSubmit={handleSubmit} className={classes.formRoot}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                >
                    <Box>
                        <Paper className={classes.cardContentContainer}>
                            <Typography>
                                Credit yourself and other creators of this content.
                                Credits are displayed in posts and help to tag creators in
                                social posts.
                            </Typography>
                        </Paper>
                    </Box>
                    <Box paddingY={2}>
                        <Paper className={classes.cardContentContainer}>
                            <CardSectionHeader sectionTitle={'Credit Type'} />
                            {renderCreditTypes()}
                        </Paper>
                    </Box>
                    <Box paddingBottom={2}>{renderCreditFieldBasicInfo()}</Box>
                    {manualEntry && (
                        <Box paddingBottom={2}>
                            <Paper className={classes.cardContentContainer}>
                                <CardSectionHeader sectionTitle={'Social Media'} />
                                {renderSocialMediaHandles()}
                            </Paper>
                        </Box>
                    )}
                </Box>
            </form>
        );
    };

    return (
        <Dialog
            //position={'relative'}
            PaperProps={{
                // Disable paper override
                classes: {
                    root: classes.disablePaperMargins,
                },
            }}
            fullScreen={mobile}
            open={isOpen}
            onClose={closeModal}
            TransitionComponent={Transition}
            onEntering={onEnter}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            //onBackdropClick={closeModal}
            //NOTE: this line is necessary to "cleanse" the state of formik
            // and avoid weird errors when editing existing users
            onExit={() => {
                resetForm();
            }}
        >
            {/**Dialogue Headers */}
            {!mobile && <DialogTitle id={'scroll-dialog-title'}>Add Credit</DialogTitle>}
            {mobile && renderDialogueHeader()}
            <DialogContent
                className={classes.dialogueContentContainer}
                dividers={!mobile}
                ref={dialogContentRef}
            >
                {loading ? (
                    <div className={classes.loadingContainer}>
                        <CircularProgress color={'primary'} size={60} />
                    </div>
                ) : error ? (
                    <div className={classes.loadingContainer}>
                        <Typography variant={'p'}>
                            Error connecting to server...
                        </Typography>
                        <Button variant={'text'} color={'primary'} onClick={closeModal}>
                            Close
                        </Button>
                    </div>
                ) : (
                    renderAddCreditModal()
                )}
            </DialogContent>

            <DialogActions className={classes.dialogActionsContainer}>
                <Button
                    size={'large'}
                    variant={'text'}
                    color={'primary'}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    disabled={!isValid}
                    size={'large'}
                    variant={'text'}
                    color={'primary'}
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddCreditModal.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddCreditModal);
