import React from 'react';
import {
    Paper,
    useTheme,
    useMediaQuery,
    CircularProgress,
    Hidden,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
    ActionAreaContainer,
    GridContainer,
    GridItem,
    PageTitleDescription,
    ScreenContainer,
} from '../../components';
import PropTypes from 'prop-types';
import styles from './styles';
import { centsToDollars, interpolateSubmissionType } from '../../lib/util';

const SubmissionTypeForm = ({ submissionTypes, loading, error, refetch, ...props }) => {
    const { classes, setField, nextStep, submission, handleBackPress } = props;

    const productConfig = {};

    const handleChange = (event, imperativeValue = null) => {
        setField('submission', {
            ...submission,
            submissionType: productConfig[imperativeValue || event.target.value],
        });
    };

    if (loading)
        return (
            <ScreenContainer>
                <div className={classes.msgContainer}>
                    <CircularProgress />
                </div>
            </ScreenContainer>
        );
    else if (error)
        return (
            <ScreenContainer>
                <div className={classes.msgContainer}>
                    <Typography>Failed to load page</Typography>
                    <Button type="text" color="primary">
                        Retry
                    </Button>
                </div>
            </ScreenContainer>
        );
    else {
        submissionTypes.forEach((type) => {
            productConfig[type.value] = type;
        });

        if (!submission.submissionType) {
            submission.submissionType = submissionTypes[0];
            handleChange(null, 'Standard');
        }
    }

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleSubmit = () => {
        nextStep();
    };

    const renderRadioGroupValue = (product, productPrice, index) => {
        return (
            <FormControlLabel
                key={index}
                value={product.value}
                control={<Radio color="primary" />}
                label={
                    <Typography>
                        {interpolateSubmissionType(product.value)}
                        {productPrice}
                    </Typography>
                }
            />
        );
    };

    const renderOptionSelector = () => {
        const productPrice = centsToDollars(
            productConfig[submission.submissionType.value].price,
        );

        return (
            <GridContainer
                direction="row"
                spacing={1}
                justify={mobile ? 'space-between' : 'flex-start'}
                alignItems="center"
                className={classes.productsContainer}
            >
                <GridItem xs={6}>
                    <RadioGroup
                        aria-label="submissionType"
                        name="submissionType"
                        value={submission.submissionType.value}
                        onChange={handleChange}
                    >
                        {Object.values(productConfig).map((product, index) => {
                            const priceInDollars = centsToDollars(product.price);
                            const productPrice = !mobile ? ` - $${priceInDollars}` : '';

                            return renderRadioGroupValue(product, productPrice, index);
                        })}
                    </RadioGroup>
                </GridItem>
                {mobile && (
                    <React.Fragment>
                        <GridItem xs>
                            <Divider
                                orientation={'vertical'}
                                variant={'fullWidth'}
                                className={classes.divider}
                            />
                        </GridItem>

                        <GridItem xs={5}>
                            <div className={classes.priceContainer}>
                                <div>
                                    <Typography color={'textSecondary'}>Price</Typography>
                                    <Typography
                                        variant="h3"
                                        className={clsx(
                                            classes.priceText,
                                            submission.submissionType.onSale &&
                                                classes.onSaleText,
                                        )}
                                        align={'left'}
                                    >
                                        $
                                        {submission.submissionType.onSale
                                            ? submission.submissionType.markDownPrice.toFixed(
                                                  2,
                                              )
                                            : productPrice}
                                    </Typography>
                                    {/**
                                    TODO: Implement when we are doing price point for specials
                                     */}
                                    {submission.submissionType.onSale && (
                                        <div className={classes.saleInfoContainer}>
                                            <div>
                                                <Typography
                                                    color={'textSecondary'}
                                                    variant={'caption'}
                                                    component={'span'}
                                                    display={'inline'}
                                                    align={'left'}
                                                >
                                                    {'Regular Price: '}
                                                </Typography>
                                                <Typography
                                                    color={'textSecondary'}
                                                    variant={'caption'}
                                                    display={'inline'}
                                                    className={
                                                        classes.listingPriceRegular
                                                    }
                                                >
                                                    $
                                                    {submission.submissionType.price.toFixed(
                                                        2,
                                                    )}
                                                </Typography>
                                            </div>
                                            <Typography
                                                color={'primary'}
                                                variant={'caption'}
                                                align={'left'}
                                            >
                                                Sale Ends:{' '}
                                                {submission.submissionType.saleExpDate}
                                            </Typography>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </GridItem>
                    </React.Fragment>
                )}
            </GridContainer>
        );
    };

    return (
        <ScreenContainer>
            <PageTitleDescription
                pageTitle={'SUBMISSION TYPE'}
                description={`Submit Music, Videos, Written Articles or Photo Sets. We also accept Products/Services for Sponsored Posts.`}
            />
            <Paper className={classes.optionSelectorContainer}>
                {renderOptionSelector()}
                <div className={classes.productDescriptionContainer}>
                    <Typography variant={'subtitle1'}>What You Get</Typography>
                    <Typography>
                        {submission.submissionType.productDescription}
                    </Typography>
                </div>
            </Paper>
            <ActionAreaContainer>
                <Hidden smDown>
                    <Button
                        variant={'contained'}
                        // fullWidth
                        size={'large'}
                        color={'secondary'}
                        onClick={handleBackPress}
                    >
                        Back
                    </Button>
                    <div className={classes.buttonSpacer} />
                </Hidden>
                <Button
                    size={'large'}
                    fullWidth={mobile}
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleSubmit}
                >
                    Next
                </Button>
            </ActionAreaContainer>
        </ScreenContainer>
    );
};

SubmissionTypeForm.propTypes = {
    classes: PropTypes.object.isRequired,
    nextStep: PropTypes.func.isRequired,
    setField: PropTypes.func.isRequired,
    submission: PropTypes.object.isRequired,
    handleBackPress: PropTypes.func.isRequired,
};

export default withStyles(styles)(SubmissionTypeForm);
