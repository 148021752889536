import React from 'react';
import {
    Divider,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Slide,
    useMediaQuery,
    Hidden,
    Box,
    Checkbox,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles, useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import { Formik } from 'formik';
import { CreditCardOutline, Paypal, AlertCircleOutline } from 'mdi-material-ui';
import Router from 'next/router';
import { withSnackbar } from 'notistack';
import ReactDOM from 'react-dom';
import { injectStripe } from 'react-stripe-elements';
import { mixed, object as yupObject, string, number, boolean } from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import {
    ActionAreaContainer,
    CardSectionHeader,
    GridContainer,
    GridItem,
    PageTitleDescription,
    ScreenContainer,
} from '../../components';
import withLoadingModal from '../../hocs/LoadingModal/WithLoadingModal';
import CreditCardForm from './CreditCardForm';
import NewAccountForm from './NewAccountForm';
import styles from './styles';
import { useMutation } from '@apollo/react-hooks';
import { CHECKOUT } from '../../lib/mutations/orders';
import { centsToDollars, normalizeLink, interpolateSubmissionType } from '../../lib/util';
import { useApolloClient } from '@apollo/react-hooks';
import { reportError } from '../../lib/errors';
import { USERNAME_EXISTS } from '../../lib/queries/users';
import { isValidPassword, signup, notTooGuessable } from '../../lib/auth';

// Provide a container for which the paypal button can be injected into.
let PayPalCheckoutButton = () => <></>;

const CheckoutForm = (props) => {
    const {
        currentUser,
        hydrateUser,
        classes,
        submission,
        user,
        credits,
        toggleLoadingModal,
        setLoadingText,
        enqueueSnackbar,
        closeSnackbar,
        stripe,
        handleBackPress,
    } = props;

    const isNewUser = currentUser ? false : true;
    const preferred = currentUser && currentUser.profile.preferred;

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        window && window.scrollTo(0, 0);
    }, []);

    // Keeps track of the payment type. Can be one of ['PayPal', 'Card']
    // Note that this field is not validated or tracked by Yup or Formik.
    // This allows the paymentType variable to dictatate what forms and which
    // validation schema to use.
    const [paymentType, setPaymentType] = React.useState('Card');
    const [paypalOrderId, setPaypalOrderId] = React.useState(null);

    const [paymentErrorWithNewUser, setPaymentErrorWithNewUser] = React.useState(false);

    /**
     * Handler for changing the value of the payment method field
     */
    const handlePaymentMethodChange = (event) => {
        setPaymentType(event.target.value);
    };

    /**
     *  Because of SSR, the 'paypal' is not definined onRender. thus we provide
     * a container for the paypal buttons to be injected into, and render the
     * component itself on the client-side only.
     */
    React.useEffect(() => {
        if (window) {
            PayPalCheckoutButton = paypal.Buttons.driver('react', { React, ReactDOM });
        }
    }, []);

    /**
     * Handler func for field changes in the formik form. Will set the new state value
     * as well a mark the field as "touched" for validation purposes.
     */
    const handleInputChange = (
        formikBag,
        fieldName,
        event,
        imperativeValue = undefined,
    ) => {
        const { handleChange, setFieldTouched, setFieldValue } = formikBag;

        // if value is being set imperatively, use the standard React event.target.value scheme.
        if (imperativeValue !== undefined) {
            setFieldValue(fieldName, imperativeValue);
        } else {
            handleChange(event);
        }
        setFieldTouched(fieldName, true);
    };

    /**
     * Returns a dynamically created yup validation schema to be used by
     * Formik. The base is required regardless of the
     * end result. However, we desctructure additional newAccountForm and creditCardForm
     * validation parameters depending on whether the user is signed in
     * or not, and what the payment method selection currently is
     */
    const getYupValidationSchema = () => {
        const newAccountFormValidationSchema = {
            firstName: string().required('This field is required'),
            lastName: string().required('This field is required'),
            email: string()
                .email('Please enter a valid email')
                .test('noWhiteSpace', 'Emails cannot contain spaces', (value) => {
                    return !/\s/.test(value);
                })
                .required('This field is required'),
            username: string()
                .required('This field is required')
                .test('noWhiteSpace', 'Usernames cannot contain spaces', (value) => {
                    return !/\s/.test(value);
                }),
            password: string()
                .required('This field is required')
                .test('requirements', 'Password requirements not met', (value) => {
                    if (!value) return false;
                    return isValidPassword(value);
                })
                .test('notTooGuessable', 'password is too guessable', (value) => {
                    if (!value) return false;
                    return notTooGuessable(value);
                }),
            confirmPassword: string()
                .test('passwordsMatch', 'Passwords must match', function(value) {
                    if (!value) return false;
                    return value === this.options.parent.password;
                })
                .required('This field is required'),
        };

        const creditCardFormValidationSchema = {
            cardInfo: mixed().test(
                'ValidCardInfo',
                'Please enter a valid card',
                // A func returning true means it is a valid input
                (value) => value && value.complete && !value.error,
            ),
            nameOnCard: string().required('This field is required'),
            address: string().required('An address is required to make a payment'),
            city: string().required('a city is required to make a payment'),
            state: string(),
            country: string(),
        };

        const recaptchaValidationSchema = {
            recaptcha: string().required(),
        };

        const baseValidationSchema = {
            // Only include new account field validation if this is a new user
            ...(isNewUser && newAccountFormValidationSchema),

            // Only include the card form validation if we are checking out via Stripe
            ...(paymentType === 'Card' && !preferred && creditCardFormValidationSchema),

            ...((paymentType === 'Card' || preferred) && recaptchaValidationSchema),
            //TODO: Comment terms acceptance box when we have legal documentation
            // acceptsTerms: boolean()
            //     .oneOf([true], 'You must accept terms to continue')
            //     .required('You must accept terms to continue'),
        };

        if (!Object.keys(baseValidationSchema).length) {
            return baseValidationSchema;
        }

        return yupObject(baseValidationSchema);
    };

    /**
     * Dynamically creates initial values based on a number of
     * factors including:
     * - Whether the user does not have an account
     * - Whether the current payment method is card or paypal
     */
    const getInitialFormikValues = () => {
        const newAccountFields = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            username: '',
            password: '',
            confirmPassword: '',
            guessabilityLevel: 0,
        };

        const creditCardforms = {
            cardInfo: {},
            nameOnCard: '',
            address: '',
            city: '',
            state: '',
            country: 'United States',
        };

        const recaptchaFields = {
            recaptcha: '',
        };

        const baseFields = {
            ...(isNewUser && newAccountFields),
            ...(paymentType === 'Card' && !preferred && creditCardforms),
            ...((paymentType === 'Card' || preferred) && recaptchaFields),
            //TODO: Comment terms acceptance box when we have legal documentation
            //acceptsTerms: false,
        };

        return baseFields;
    };

    const [checkout] = useMutation(CHECKOUT);

    const apolloClient = useApolloClient();
    const checkUniqueness = async (args) => {
        const { data, errors } = await apolloClient.query({
            query: USERNAME_EXISTS,
            variables: args,
            fetchPolicy: 'network-only',
        });

        if (errors)
            return {
                emailUsed: true,
                usernameUsed: true,
            };
        else if (data) return data;
    };

    /**
     * Handler for submitting the submission form itself.
     */
    const handleSubmit = async (values, actions) => {
        // Prior to starting submission, we verify that the new account form
        // is valid.
        if (isNewUser) {
            const { usernameUsed } = await checkUniqueness({
                username: values.username.trim(),
            });

            if (usernameUsed) {
                actions.setFieldError('username', 'This username is already in use');
                return;
            }
        }

        let stepNumber = 1;
        let stepTotal = 2;

        toggleLoadingModal();

        if (!currentUser) {
            stepTotal = 3;

            setLoadingText(`Creating your account (${stepNumber}/${stepTotal})`);
            try {
                // signup user to cognito
                const { username, email, firstName, lastName, password } = values;

                await signup({
                    username: username.trim(),
                    email: email.trim(),
                    firstName,
                    lastName,
                    password,
                });

                stepNumber++;

                enqueueSnackbar('Account creation successful', {
                    persist: false,
                    variant: 'info',
                    action: SnackbarActions,
                });
            } catch (err) {
                console.error(err);
                enqueueSnackbar('Account creation failed', {
                    persist: false,
                    variant: 'error',
                    action: SnackbarActions,
                });
                return;
            }
        }

        const {
            contentType,
            contentUrl,
            contentTitle,
            contentImage,
            contentDescription,
        } = submission;

        const submissionType = preferred ? 'Preferred' : submission.submissionType.value;
        const total = preferred ? 0 : submission.submissionType.price;

        const input = {
            paymentType,
            paymentMethod: {},
            submission: {
                contentType,
                contentUrl,
                contentTitle,
                contentImage,
                contentDescription,
                submissionType: submissionType,
                credits: credits.map((credit) => {
                    const socialMedia = credit.socialMedia
                        .filter(({ link }) => link.length)
                        .map(({ type, link }) => ({ type, link }));
                    return {
                        ...credit,
                        socialMedia,
                    };
                }),
            },
            total: total,
        };

        if (paymentType === 'PayPal') {
            setLoadingText(`Retrieving PayPal order (${stepNumber}/${stepTotal})`);
            input.paymentMethod.paypalOrderId = paypalOrderId;
        } else if (preferred) {
            input.paymentType = 'Free';
        } else {
            setLoadingText(`Validating credit card (${stepNumber}/${stepTotal})`);
            input.paymentType = 'Stripe';
            try {
                // generate and set stripeCardTokenId
                let { token } = await stripe.createToken({
                    type: 'card',
                    name: values.nameOnCard,
                    address_line1: values.address,
                    address_city: values.city,
                    address_zip: values.cardInfo.value.postalCode,
                    address_state: values.state,
                    address_country: values.country,
                    currency: 'usd',
                });
                input.paymentMethod.stripeCardTokenId = token.id;
            } catch (err) {
                reportError(err, {
                    severity: 'error',
                });
                enqueueSnackbar('Invalid card information', {
                    persist: true,
                    variant: 'error',
                    action: SnackbarActions,
                });
                return;
            }
        }

        stepNumber++;

        try {
            setLoadingText(`Creating your submission (${stepNumber}/${stepTotal})`);
            const { data } = await checkout({
                variables: { input },
            });

            const { orderNumber } = data.checkout.submissions[0];

            toggleLoadingModal();

            const params = {
                submissionId: orderNumber,
            };

            if (isNewUser) params.isNewUser = true;

            Router.push({
                pathname: '/confirmation',
                query: params,
            });
        } catch (err) {
            const errorMessage =
                err.message.replace('GraphQL error: ', '') ||
                'Failed to create your submission';

            toggleLoadingModal();

            if (!currentUser) {
                await hydrateUser(false);
                actions.resetForm({
                    cardInfo: values.cardInfo,
                    recaptcha: values.recaptcha,
                });
                setPaymentErrorWithNewUser(true);
                window && window.scrollTo(0, 0);
            }

            reportError(err, {
                severity: 'error',
            });

            enqueueSnackbar(errorMessage, {
                persist: true,
                variant: 'error',
                action: SnackbarActions,
            });
        }
    };

    const SnackbarActions = (key) => (
        <React.Fragment>
            <Button
                onClick={() => {
                    closeSnackbar(key);
                }}
                style={{ color: 'white' }}
            >
                {'Dismiss'}
            </Button>
        </React.Fragment>
    );

    /**
     * Utility function for checking whether the current radio button is active or not
     */
    const isRadioActive = (radioValue) => {
        return paymentType === radioValue;
    };

    const renderTotalsCard = () => {
        const priceString = preferred
            ? 0
            : centsToDollars(submission.submissionType.price);

        return (
            <Paper className={classes.totalsCard}>
                <CardSectionHeader sectionTitle={'Order Info'} />
                <GridContainer
                    direction={'row'}
                    justify={'space-between'}
                    alignItems={'center'}
                    className={classes.cardrow}
                >
                    <GridItem>
                        <Typography>
                            {preferred
                                ? 'Preferred Submission'
                                : interpolateSubmissionType(
                                      submission.submissionType.value,
                                  )}
                        </Typography>
                    </GridItem>
                    <GridItem>
                        <Typography>${priceString}</Typography>
                    </GridItem>
                </GridContainer>
                <Divider variant={'fullWidth'} />
                <GridContainer
                    direction={'row'}
                    justify={'space-between'}
                    alignItems={'center'}
                    className={classes.cardrow}
                >
                    <GridItem>
                        <Typography variant={'subtitle1'}>Total</Typography>
                    </GridItem>
                    <GridItem>
                        <Typography variant={'subtitle1'}>${priceString}</Typography>
                    </GridItem>
                </GridContainer>
            </Paper>
        );
    };

    const renderRadioCard = (value, Icon, displayText) => {
        return (
            <div className={classes.radioCardContainer}>
                <FormControlLabel
                    value={value}
                    control={<Radio color="primary" />}
                    className={clsx(
                        classes.radioCard,
                        isRadioActive(value) && classes.activeRadioCard,
                    )}
                    label={
                        <div className={classes.paymentOptionContainer}>
                            <Icon
                                className={clsx(
                                    classes.paymentIcon,
                                    isRadioActive(value) && classes.blueText,
                                )}
                            />
                            <Typography
                                className={isRadioActive(value) ? classes.blueText : null}
                            >
                                {displayText}
                            </Typography>
                        </div>
                    }
                />
            </div>
        );
    };

    const renderPaymentCard = (formikBag) => {
        return (
            <Paper>
                <CardSectionHeader sectionTitle={'Payment Method'} />
                <RadioGroup
                    id={'paymentType'}
                    aria-label="Payment Type"
                    name="paymentType"
                    value={paymentType}
                    onChange={(e) => {
                        handlePaymentMethodChange(e);
                        formikBag.validateForm();
                    }}
                    className={classes.radioGroupContainer}
                >
                    {renderRadioCard('Card', CreditCardOutline, 'Card')}
                    {renderRadioCard('PayPal', Paypal, 'PayPal')}
                </RadioGroup>
                {paymentType === 'PayPal' && (
                    <Typography>
                        PayPal will open in a new window upon checkout.
                    </Typography>
                )}
                {paymentType === 'Card' && (
                    <CreditCardForm
                        handleInputChange={handleInputChange}
                        formikBag={formikBag}
                    />
                )}
            </Paper>
        );
    };

    const renderNewAccountCard = (formikBag) => {
        return (
            <Paper>
                <CardSectionHeader sectionTitle={'Account'} />
                <div className={classes.newAccountInstructions}>
                    <Typography variant={'subtitle2'}>
                        Finish creating your new account
                    </Typography>
                    <Typography>
                        Track your submissions by creating an Elevator account
                    </Typography>
                </div>
                <NewAccountForm
                    handleInputChange={handleInputChange}
                    formikBag={formikBag}
                />
            </Paper>
        );
    };

    const renderCheckoutButton = (formikBag) => {
        if (paymentType === 'Card') {
            return (
                <Button
                    variant={'contained'}
                    disabled={!formikBag.isValid}
                    color={'primary'}
                    size={'large'}
                    fullWidth
                    onClick={formikBag.handleSubmit}
                >
                    {preferred ? 'Submit' : 'Pay & Submit'}
                </Button>
            );
        } else {
            return (
                <Slide in direction={'up'}>
                    <div className={classes.paypalButtonContainer}>
                        {renderPayPalState(formikBag)}
                    </div>
                </Slide>
            );
        }
    };

    const getPayPalItemDescription = () => {
        if (preferred) {
            return 'Preferred Submission';
        }

        if (submission.submissionType.value === 'Standard') {
            return `${submission.submissionType.value} Submission`;
        } else {
            return `${submission.submissionType.value} Placement`;
        }
    };

    const renderPayPalState = (formikBag) => {
        if (formikBag.isValid) {
            return (
                <PayPalCheckoutButton
                    style={{
                        label: 'pay',
                        layout: 'horizontal',
                        color: 'gold',
                        tagline: false,
                    }}
                    createOrder={(data, actions) => {
                        const priceString = preferred
                            ? 0
                            : centsToDollars(submission.submissionType.price);
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: 'USD',
                                        value: priceString,
                                        breakdown: {
                                            item_total: {
                                                currency_code: 'USD',
                                                value: priceString,
                                            },
                                        },
                                    },
                                    soft_descriptor: 'ELEVATOR LLC',
                                    items: [
                                        {
                                            name: getPayPalItemDescription(),
                                            unit_amount: {
                                                currency_code: 'USD',
                                                value: priceString,
                                            },
                                            quantity: '1',
                                        },
                                    ],
                                },
                            ],
                        });
                    }}
                    onApprove={(data, _actions) => {
                        setPaypalOrderId(data.orderID);
                        formikBag.handleSubmit();
                    }}
                    onError={(error) => {
                        reportError(error, {
                            metaData: {
                                operation: 'PayPal checkout',
                            },
                        });
                        console.error(error);
                    }}
                    onCancel={() => {
                        console.log('window closed');
                    }}
                />
            );
        } else {
            return (
                <Button
                    variant={'contained'}
                    disabled
                    color={'primary'}
                    size={'large'}
                    fullWidth
                >
                    <Paypal />
                </Button>
            );
        }
    };

    const renderCaptcha = (formik) => {
        return (
            <Paper>
                <CardSectionHeader sectionTitle={'CAPTCHA'} />
                <div className={classes.captchaContainer}>
                    <ReCAPTCHA
                        sitekey={process.env.googleRecaptchaV2SiteKey}
                        onChange={(response) => {
                            handleInputChange(formik, 'recaptcha', null, response);
                        }}
                        onErrored={() => {
                            handleInputChange(formik, 'recaptcha', null, '');
                        }}
                        onExpired={() => {
                            handleInputChange(formik, 'recaptcha', null, '');
                        }}
                    />
                </div>
            </Paper>
        );
    };

    const interpoloateMobileWrapper = (component) => {
        if (mobile) {
            return <GridItem className={classes.cardContainer}>{component}</GridItem>;
        } else {
            return component;
        }
    };

    const renderSubmissionReviewRowContent = (content) => {
        return (
            <Box maxWidth={'70%'} className={classes.submissionReviewContent}>
                {typeof content === React.Node ? (
                    content
                ) : (
                    <Typography variant={'body1'}>{content}</Typography>
                )}
            </Box>
        );
    };

    const renderSubmissionReviewRowCredits = (credits) => {
        return (
            <Box maxWidth={'70%'} className={classes.submissionReviewContent}>
                {credits.map((value, index) => (
                    <Typography key={index} component={'span'} variant={'body1'}>
                        {value.type} - {value.name}
                        <Box
                            paddingLeft={2}
                            paddingTop={1}
                            display={'flex'}
                            flexDirection={'column'}
                        >
                            {value.socialMedia.map(({ link }, index) => {
                                return (
                                    <Box key={index} paddingBottom={2}>
                                        <a
                                            href={normalizeLink(link)}
                                            target={'_blank'}
                                            className={classes.link}
                                        >
                                            {link}
                                        </a>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Typography>
                ))}
            </Box>
        );
    };

    const renderSubmissionReviewRow = (label, content, isCredit = false) => {
        return (
            <Box
                width={'100%'}
                paddingY={1}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                minHeight={'50px'}
            >
                <Box maxWidth={'30%'} width={'100%'}>
                    <Typography variant={'caption'} className={classes.fieldName}>
                        {label}
                    </Typography>
                </Box>
                {isCredit
                    ? renderSubmissionReviewRowCredits(content)
                    : renderSubmissionReviewRowContent(content)}
            </Box>
        );
    };

    const renderSubmissionReview = () => {
        return (
            <Paper>
                <CardSectionHeader sectionTitle={'Your Submission'} />
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'flex-start'}
                    alignItems={'flex-start'}
                    width={'100%'}
                >
                    {renderSubmissionReviewRow(
                        'Submission Title',
                        submission.contentTitle,
                    )}

                    {renderSubmissionReviewRow(
                        'Streaming Link',
                        <a
                            href={submission.contentUrl}
                            target={'_blank'}
                            className={classes.link}
                        >
                            {submission.contentUrl}
                        </a>,
                    )}

                    {renderSubmissionReviewRow(
                        'Image',
                        submission.contentImage ? (
                            <a
                                href={URL.createObjectURL(submission.contentImage)}
                                target={'_blank'}
                            >
                                <img
                                    className={classes.imagePreview}
                                    src={URL.createObjectURL(submission.contentImage)}
                                />
                            </a>
                        ) : (
                            '(No image attached)'
                        ),
                    )}

                    {renderSubmissionReviewRow(
                        'Description',
                        submission.contentDescription,
                    )}

                    {renderSubmissionReviewRow('Credits', credits, true)}
                </Box>
            </Paper>
        );
    };

    const renderPaymentErrorCard = () => (
        <GridItem className={classes.cardContainer}>
            <Paper className={classes.paymentErrorContainer}>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                >
                    <AlertCircleOutline height={'1.25rem'} />
                    <Typography
                        variant={'h6'}
                        display={'inline-block'}
                        className={classes.paymentErrorTitle}
                    >
                        Oops!
                    </Typography>
                </Box>
                <Typography>
                    Your account was created, but something went wrong with your
                    transaction. Please check your information and try again!
                </Typography>
            </Paper>
        </GridItem>
    );

    return (
        <ScreenContainer>
            <PageTitleDescription
                pageTitle={preferred ? 'REVIEW' : 'CHECKOUT'}
                description={
                    'Review your submission information to make sure it is correct. You will be notified via email when your submission has been received and reviewed.'
                }
            />
            <GridContainer
                direction={mobile ? 'column' : 'row'}
                justify={mobile ? 'flex-start' : 'space-between'}
                spacing={mobile ? 0 : 4}
            >
                <GridItem
                    className={classes.leftContainer}
                    lg={mobile ? 3 : 8}
                    md={mobile ? 3 : 7}
                >
                    <Formik
                        enableReinitialize
                        validateOnMount
                        validationSchema={getYupValidationSchema()}
                        initialValues={getInitialFormikValues()}
                        isInitialValid={!Object.keys(getYupValidationSchema()).length}
                        validateOnChange={false}
                        onSubmit={handleSubmit}
                    >
                        {(formikBag) => {
                            return (
                                <form
                                    onSubmit={(event) => {
                                        event.preventDefault();
                                    }}
                                    className={classes.formRoot}
                                >
                                    <GridItem className={classes.cardContainer}>
                                        {renderSubmissionReview()}
                                    </GridItem>

                                    {mobile && (
                                        <GridItem className={classes.cardContainer}>
                                            {renderTotalsCard()}
                                        </GridItem>
                                    )}

                                    {paymentErrorWithNewUser && renderPaymentErrorCard()}

                                    {!preferred &&
                                        interpoloateMobileWrapper(
                                            renderPaymentCard(formikBag),
                                        )}
                                    {isNewUser &&
                                        interpoloateMobileWrapper(
                                            renderNewAccountCard(formikBag),
                                        )}

                                    {(preferred || paymentType === 'Card') &&
                                        renderCaptcha(formikBag)}

                                    {/**TODO: Comment terms acceptance box when we have legal documentation */}
                                    {/* <GridItem className={classes.cardContainer}>
                                        <Paper>
                                            <CardSectionHeader sectionTitle={'TERMS'} />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={'acceptsTerms'}
                                                        checked={
                                                            formikBag.values.acceptsTerms
                                                        }
                                                        onChange={(event) =>
                                                            handleInputChange(
                                                                formikBag,
                                                                'acceptsTerms',
                                                                null,
                                                                event.target.checked,
                                                            )
                                                        }
                                                        color={'primary'}
                                                        inputProps={{
                                                            'aria-label': 'acceptsTerms',
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        gutterBottom={false}
                                                        variant={'body2'}
                                                    >
                                                        I accept the ELEVATOR terms of use
                                                        and privacy policy
                                                    </Typography>
                                                }
                                            />
                                        </Paper>
                                    </GridItem> */}

                                    <ActionAreaContainer direction={'column'}>
                                        {renderCheckoutButton(formikBag)}
                                        <div className={classes.buttonSpacer} />

                                        <Hidden smDown>
                                            <Button
                                                variant={'contained'}
                                                size={'large'}
                                                color={'secondary'}
                                                onClick={handleBackPress}
                                                fullWidth
                                            >
                                                Back
                                            </Button>
                                            <div className={classes.buttonSpacer} />
                                        </Hidden>
                                    </ActionAreaContainer>
                                </form>
                            );
                        }}
                    </Formik>
                </GridItem>
                {!mobile && (
                    <GridItem
                        className={classes.rightContainer}
                        lg={4}
                        md={mobile ? 3 : 5}
                    >
                        {renderTotalsCard()}
                    </GridItem>
                )}
            </GridContainer>
        </ScreenContainer>
    );
};

const StyledCheckoutForm = withStyles(styles)(CheckoutForm);
const CheckoutFormWithModal = withLoadingModal(StyledCheckoutForm);
const CheckoutFormWithStripe = injectStripe(CheckoutFormWithModal);

export default withSnackbar(CheckoutFormWithStripe);
