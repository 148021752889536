import gql from 'graphql-tag';

export const CHECKOUT = gql`
    mutation checkout($input: CheckoutInput!) {
        checkout(input: $input) {
            orderId
            isPaid
            ipAddr
            amountPaid
            ccLast4
            transactionId
            submissions {
                submissionId
                orderNumber
                contentTitle
                credits {
                    name
                    typeId
                }
            }
            userId
            user {
                username
            }
        }
    }
`;
