import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import { mixed, object as yupObject, string } from 'yup';
import { PageTitleDescription, ScreenContainer } from '../../components';
import SubmissionContentForm from './SubmissionContentForm';
import PropTypes from 'prop-types';
import styles from './styles';

const ContentForm = (props) => {
    const {
        classes,
        nextStep,
        setField,
        hasBacktracked,
        submission,
        handleBackPress,
    } = props;

    React.useEffect(() => {
        window && window.scrollTo(0, 0);
    }, []);

    const MAX_FILE_SIZE = 20971520; // ~ 20 Megabytes
    const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

    /**
     * Basic Form Validation Schema. This Yup object is used by Formik to
     * validate all fields in the form as well as provide adequate help text
     * when necessary.
     */
    const validationSchema = yupObject({
        contentUrl: string()
            .url(`Please enter a valid url. All content links must start with 'https://'`)
            .required('A valid url is required to make a submission')
            .test(
                'allowedDomain',
                'Sponsored submissions require a valid YouTube video link',
                (value) => {
                    // If no submission type is defined, user may be preferred. Ignore
                    // youtube validation
                    if (!submission.submissionType) return true;

                    // if any other submission other than sponsored, ignore this test
                    if (submission.submissionType.value !== 'Paid') return true;

                    let url;
                    try {
                        url = new URL(value);
                    } catch (error) {
                        return false;
                    }

                    return [
                        'www.youtube.com',
                        'youtube.com',
                        'www.youtu.be',
                        'm.youtube.com',
                        'youtu.be',
                    ].includes(url.hostname);
                },
            ),

        contentTitle: string().required('This field is required'),
        contentImage: mixed()
            .test(
                'SupportImageTypes',
                'We do not support this kind of image',
                // A func returning true means it is a valid input
                (value) => (value ? SUPPORTED_IMAGE_FORMATS.includes(value.type) : true),
            )
            .test(
                'MaxfileSize',
                'Please provide an image with size <= 20MB',
                // A func returning true means it is a valid input
                (value) => (value ? value.size < MAX_FILE_SIZE : true),
            ),
        contentDescription: string().required('This field is required'),
    });

    // Base form defaults used my formik. These fields are NOT UPDATED IN STATE.
    // these fields are accessible via the onSubmit function handler for the from.
    const initialValues = {
        contentUrl: submission.contentUrl,
        contentTitle: submission.contentTitle,
        contentImage: submission.contentImage,
        contentDescription: submission.contentDescription,
    };

    const handleSubmit = (values) => {
        setField('submission', {
            ...submission,
            ...values,
        });

        nextStep();
    };

    return (
        <ScreenContainer>
            <PageTitleDescription
                pageTitle={'SUBMISSIONS'}
                description={`Submit Music, Videos, Written Articles or Photo Sets. We also accept Products/Services for Sponsored Posts.`}
            />
            <Formik
                children={(props) => (
                    <SubmissionContentForm
                        {...props}
                        handleBackPress={handleBackPress}
                        submissionType={submission.submissionType}
                    />
                )}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                // If the user has been on this form before (i.e., they pressed back)
                // Form should not be disabled
                isInitialValid={hasBacktracked}
            />
        </ScreenContainer>
    );
};

ContentForm.propTypes = {
    classes: PropTypes.object.isRequired,
    nextStep: PropTypes.func.isRequired,
    setField: PropTypes.func.isRequired,
    handleBackPress: PropTypes.func.isRequired,
};

export default withStyles(styles)(ContentForm);
