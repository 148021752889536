const styles = (theme) => ({
    // Root Styles
    cardContainer: {
        width: '100%',
        paddingBottom: theme.spacing(2),
    },
    cardrow: {
        height: '65px',
    },
    textField: {
        width: '100%',
        position: 'relative',
    },
    formRoot: {
        width: '100%',
    },
    totalsCard: {
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.background.paper,
        },
    },

    paymentErrorContainer: {
        backgroundColor: theme.palette.common.darkYellow,
    },
    paymentErrorTitle: {
        marginLeft: '4px',
    },

    // Root containers for desktop
    leftContainer: {
        width: '100%',
        minHeight: '60vh',
    },
    rightContainer: {
        width: '100%',
        minHeight: '40vh',
    },

    // Radio Groups
    radioGroupContainer: {
        minHeight: '80px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    radioCardContainer: {
        paddingRight: theme.spacing(2),
    },
    radioCard: {
        padding: theme.spacing(1),
        margin: 0,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
    },
    activeRadioCard: {
        backgroundColor: theme.palette.primary.main + '1A',
        border: `1px solid ${theme.palette.primary.main}`,
    },
    paymentOptionContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    paymentIcon: {
        marginRight: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    blueText: {
        color: theme.palette.primary.main,
    },

    // New Account form
    newAccountInstructions: {
        paddingBottom: theme.spacing(2),
    },

    // Custom Stripe Elements
    cardInputContainer: {
        padding: theme.spacing(2, 1),
        backgroundColor: theme.palette.secondary.main,
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 0,
        borderBottom: '1px solid #9E9E9E',
    },
    errorText: {
        color: 'red',
    },
    activeText: {
        color: 'black',
    },
    defaultText: {
        color: theme.palette.text.secondary,
    },
    menu: {
        padding: 0,
        maxHeight: '300px',
    },

    paypalButtonContainer: {
        width: '100%',
    },

    captchaContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    termsCheckbox: {
        minHeight: '40px',
    },
    buttonSpacer: {
        height: theme.spacing(2),
    },

    // Submission Review
    submissionReviewContent: {
        overflowWrap: 'break-word',
        wordWrap: 'break-word',

        '-ms-word-break': 'break-all',
        /* Instead use this non-standard one: */
        wordBreak: 'break-word',
    },
    imagePreview: {
        width: '200px',
        objectFit: 'fill',
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
    },
    fieldName: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },

    // New Account Form
    checkmark: {
        color: theme.palette.passwordFeedback.success,
    },
});

export default styles;
