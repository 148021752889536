import React from 'react';
import { TextField, withStyles, InputAdornment, Box } from '@material-ui/core';
import {
    GridItem,
    GridContainer,
    ToggleShowPasswordsButton,
    PasswordFeedback,
} from '../../components';
import styles from './styles';
import { isValidPassword, notTooGuessable } from '../../lib/auth';
import { Check } from 'mdi-material-ui';

const NewAccountForm = (props) => {
    const { classes, formikBag, handleInputChange } = props;
    const { values, errors, touched } = formikBag;

    const [showPasswords, toggleShowPasswords] = React.useState(false);

    const renderPasswordFieldEndAdornment = () => {
        return (
            <InputAdornment position="end">
                <Box paddingRight={1}>
                    <Check fontSize={'small'} className={classes.checkmark} />
                </Box>
            </InputAdornment>
        );
    };

    return (
        <GridContainer direction={'column'} spacing={3}>
            <GridItem className={classes.textField}>
                <GridContainer direction={'row'} spacing={3}>
                    <GridItem xs className={classes.textField}>
                        <TextField
                            id={'firstName'}
                            label={'First Name'}
                            value={values.firstName}
                            name={'firstName'}
                            autoComplete={'given-name'}
                            disabled
                            required
                            fullWidth
                            onChange={(event) =>
                                handleInputChange(formikBag, 'firstName', event)
                            }
                            error={touched.firstName && Boolean(errors.firstName)}
                            helperText={touched.firstName ? errors.firstName : ''}
                        />
                    </GridItem>
                    <GridItem xs className={classes.textField}>
                        <TextField
                            id={'lastName'}
                            label={'Last Name'}
                            name={'lastName'}
                            autoComplete={'family-name'}
                            value={values.lastName}
                            disabled
                            required
                            fullWidth
                            placeholder={'Doe'}
                            onChange={(event) =>
                                handleInputChange(formikBag, 'lastName', event)
                            }
                            error={touched.lastName && Boolean(errors.lastName)}
                            helperText={touched.lastName ? errors.lastName : ''}
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
            <GridItem className={classes.textField}>
                <TextField
                    id={'username'}
                    label={'Username'}
                    fullWidth
                    required
                    value={values.username}
                    onChange={(event) => handleInputChange(formikBag, 'username', event)}
                    error={touched.username && Boolean(errors.username)}
                    helperText={touched.username ? errors.username : ''}
                />
            </GridItem>
            <GridItem className={classes.textField}>
                <TextField
                    id={'email'}
                    autoComplete={'email'}
                    required
                    fullWidth
                    disabled
                    required
                    name={'email'}
                    value={values.email}
                    placeholder={'John.Doe@gmail.com'}
                    label={'Email'}
                    onChange={(event) => handleInputChange(formikBag, 'email', event)}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email ? errors.email : ''}
                />
            </GridItem>
            <GridItem className={classes.textField}>
                <TextField
                    id={'password'}
                    label={'Password'}
                    type={showPasswords ? 'text' : 'password'}
                    required
                    value={values.password}
                    name={'password'}
                    fullWidth
                    onChange={(event) => handleInputChange(formikBag, 'password', event)}
                    error={touched.password && Boolean(errors.password)}
                    helperText={<PasswordFeedback password={values.password} />}
                    InputProps={{
                        endAdornment:
                            isValidPassword(values.password) &&
                            notTooGuessable(values.password) &&
                            renderPasswordFieldEndAdornment(),
                    }}
                />
            </GridItem>
            <GridItem className={classes.textField}>
                <TextField
                    id={'confirmPassword'}
                    label={'Confirm Password'}
                    name={'confirmPassword'}
                    type={showPasswords ? 'text' : 'password'}
                    value={values.confirmPassword}
                    fullWidth
                    required
                    onChange={(event) =>
                        handleInputChange(formikBag, 'confirmPassword', event)
                    }
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    helperText={touched.confirmPassword ? errors.confirmPassword : ''}
                    InputProps={{
                        endAdornment:
                            touched.confirmPassword &&
                            !Boolean(errors.confirmPassword) &&
                            renderPasswordFieldEndAdornment(),
                    }}
                />
                <ToggleShowPasswordsButton
                    fullWidth={false}
                    color={'primary'}
                    showPasswords={showPasswords}
                    toggleShowPasswords={() => toggleShowPasswords((prev) => !prev)}
                    variant={'light'}
                />
            </GridItem>
        </GridContainer>
    );
};

NewAccountForm.propTypes = {};

export default withStyles(styles)(NewAccountForm);
