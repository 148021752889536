import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { Plus } from 'mdi-material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import {
    ActionAreaContainer,
    CreditsList,
    GridContainer,
    PageTitleDescription,
    ScreenContainer,
    Callout,
} from '../../components';
import AddCreditModal from './AddCredit';
import styles from './styles';

import { object as yupObject, string } from 'yup';
import { Formik } from 'formik';
import { Hidden, useTheme, useMediaQuery } from '@material-ui/core';
import {
    splitSocialMediaLink,
    isValidSocialDomainPrefix,
    isNotMalformedInput,
    whiteListedYoutubePaths,
} from '../../lib/util';

const CreditsForm = (props) => {
    const {
        classes,
        nextStep,
        setField,
        initialCredits,
        handleBackPress,
        currentUser,
    } = props;

    React.useEffect(() => {
        window && window.scrollTo(0, 0);
    }, []);

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const socialPrefixes = {};

    /**
     * Basic Form Validation Schema. This Yup object is used by Formik to
     * validate all fields in the form as well as provide adequate help text
     * when necessary.
     */
    const validationSchema = yupObject({
        name: string().required('A name is required to add a credit'),
        email: string().email('Please enter a valid email address'),
        instagram: string()
            .test(
                'pasted Instagram url is valid',
                'Please enter a valid instagram URL',
                (value) => {
                    return isValidSocialDomainPrefix(value, 'instagram');
                },
            )
            .test('malformed input', 'Malformed input please check username', (value) => {
                return isNotMalformedInput(value);
            }),
        facebook: string()
            .test(
                'pasted facebook url is valid',
                'Please enter a valid Facebook URL',
                (value) => {
                    return isValidSocialDomainPrefix(value, 'facebook');
                },
            )
            .test('malformed input', 'Malformed input please check username', (value) => {
                return isNotMalformedInput(value);
            }),
        youtube: string()
            .test(
                'pasted youtube url is valid',
                `Please enter a valid YouTube URL (e.g., 'youtube.com/channel/channel_id', 'youtube.com/c/channel_id' 'youtube.com/user/username')`,
                (value) => {
                    return (
                        isValidSocialDomainPrefix(value, 'youtube') &&
                        whiteListedYoutubePaths(value)
                    );
                },
            )
            .test('malformed input', 'Malformed input please check username', (value) => {
                return isNotMalformedInput(value);
            }),
        soundcloud: string()
            .test(
                'pasted soundcloud url is valid',
                'Please enter a valid SoundCloud URL',
                (value) => {
                    return isValidSocialDomainPrefix(value, 'soundcloud');
                },
            )
            .test('malformed input', 'Malformed input please check username', (value) => {
                return isNotMalformedInput(value);
            }),
        twitter: string()
            .test(
                'pasted twitter url is valid',
                'Please enter a valid Twitter URL',
                (value) => {
                    return isValidSocialDomainPrefix(value, 'twitter');
                },
            )
            .test('malformed input', 'Malformed input please check username', (value) => {
                return isNotMalformedInput(value);
            }),
    });

    const [credits, setCredits] = React.useState(initialCredits);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [editIndex, setEditIndex] = React.useState(-1);

    // Base form defaults used by formik. These fields are NOT UPDATED IN STATE.
    // these fields are accessible via the onSubmit function handler for the from.
    const baseInitialValues = {
        type: 'Artist',
        name: '',
        email: '',
        isSubmitter: false,
        instagram: '',
        youtube: '',
        soundcloud: '',
        twitter: '',
        facebook: '',
        autoLinked: false,
    };
    const [initialValues, setInitialValues] = React.useState(baseInitialValues);

    /**
     * Handles pre-conditions for adding a new credit. This will reset the form values
     * to their initial state before opening the modal.
     */
    const handleAddNew = () => {
        setInitialValues(baseInitialValues);
        toggleModal();
    };

    /**
     * Handle opening and closing the Credit Dialoague
     */
    const toggleModal = (editIndex = -1) => {
        setModalOpen((prevValue) => !prevValue);

        setEditIndex(editIndex);
    };

    /**
     * Handle transforming the output of the dialogue component
     * to the proper graphql object.
     */
    const handleSubmitModal = (values, actions) => {
        const {
            type,
            name,
            email,
            isSubmitter,
            instagram,
            youtube,
            soundcloud,
            twitter,
            facebook,
            autoLinked,
        } = values;

        //restructure state to credit with socials array
        // Each social will by dynamically included in array based on empty strings
        const socialMedia = getFullyQualifiedSocialMediaLink(
            values,
            socialPrefixes,
            autoLinked,
        );

        const creditToSave = {
            type,
            name,
            email,
            isSubmitter,
            socialMedia,
            autoLinked,
        };

        // Hand-off saving func to decide whether to edit or append credit
        saveCredit(creditToSave);
        toggleModal();
    };

    /**
     * Will modify an existing credit if we are currently editing, or will
     * push a new credit if we are creating a new one.
     */
    const saveCredit = (credit) => {
        if (editIndex !== -1) credits[editIndex] = credit;
        else credits.push(credit);

        setCredits(credits);
    };

    /**
     * Retrieves curated list of social media link values
     */
    const getSocialMediaInitValues = (index) => {
        // Retrieve the credit obj for the currently viewed index.
        let targetCredit = credits[index];
        let creditSocials = targetCredit.socialMedia;
        const fullLink = targetCredit.autoLinked === true;

        const result = {
            instagram: getSocialMediaLink(creditSocials, 'instagram', fullLink),
            youtube: getSocialMediaLink(creditSocials, 'youtube', fullLink),
            soundcloud: getSocialMediaLink(creditSocials, 'soundcloud', fullLink),
            twitter: getSocialMediaLink(creditSocials, 'twitter', fullLink),
            facebook: getSocialMediaLink(creditSocials, 'facebook', fullLink),
        };

        return result;
    };

    /**
     * Retrieves the link attribute of a social media object if it
     * exists, otherwise returns an empty string per use of empty text field.
     */
    const getSocialMediaLink = (socialMedia, type, fullLink) => {
        let socialLink = socialMedia.find((social) => social.type === type);

        if (socialLink) {
            // If the user's social media was automatically linked, prevent system from only
            // retrieving the username. Instead return entire link
            if (fullLink) {
                return socialLink.link;
            }

            // Otherwise parse out the username from link
            const [normalizedPrefix, normalizedUsername] = splitSocialMediaLink(
                socialLink.link,
            );
            socialPrefixes[socialLink.type] = normalizedPrefix;
            return normalizedUsername;
        } else {
            return '';
        }
    };

    /**
     * Using an object containing social media values and socialPrefixes for each website domain,
     * retrieve a conditionally built array of fully-qualified urls of social media links
     */
    const getFullyQualifiedSocialMediaLink = (values, socialPrefixes, linkOnly) => {
        const { instagram, youtube, soundcloud, twitter, facebook } = values;

        const socialMedia = [
            ...(instagram && [
                {
                    type: 'instagram',
                    link: linkOnly ? instagram : socialPrefixes.instagram + instagram,
                },
            ]),
            ...(youtube && [
                {
                    type: 'youtube',
                    link: linkOnly ? youtube : socialPrefixes.youtube + youtube,
                },
            ]),
            ...(soundcloud && [
                {
                    type: 'soundcloud',
                    link: linkOnly ? soundcloud : socialPrefixes.soundcloud + soundcloud,
                },
            ]),
            ...(twitter && [
                {
                    type: 'twitter',
                    link: linkOnly ? twitter : socialPrefixes.twitter + twitter,
                },
            ]),
            ...(facebook && [
                {
                    type: 'facebook',
                    link: linkOnly ? facebook : socialPrefixes.facebook + facebook,
                },
            ]),
        ];

        return socialMedia;
    };

    /**
     * Handler for opening the Add Credit Modal in Edit mode. This is
     * done by setting form initial values based on the index of the credit
     * realtive to the credits[] array.
     */
    const editCredit = (index) => {
        // Set initial values for Formik component by retrieving the
        // proper values from the credits[] array.
        const intialValues = {
            name: credits[index].name,
            email: credits[index].email,
            type: credits[index].type,
            isSubmitter: credits[index].isSubmitter,
            ...getSocialMediaInitValues(index),

            autoLinked: credits[index].autoLinked,
        };

        setInitialValues(intialValues);
        toggleModal(index);
    };

    /**
     * Handler for removing a credit from the credits[] array
     */
    const removeCredit = (index) => {
        let newCredits = credits.filter((credit, creditIndex) => creditIndex !== index);

        setCredits(newCredits);
    };

    /**
     * Handler for the "USE THESE CREDITS" button
     */
    const handleSubmit = () => {
        setField('credits', credits);
        nextStep();
    };

    const onCancel = () => {
        toggleModal();
    };

    /**
     * Iterates through the list of credits available in screen
     * and returns true if any of the credits is missing social media
     * links
     */
    const submissionCreditsMissingSocialMedia = () => {
        let missingSocialMedia = false;
        credits.forEach((credit) => {
            if (credit.socialMedia.length < 1 && !credit.autoLinked) {
                missingSocialMedia = true;
            }
        });

        return missingSocialMedia;
    };

    const renderCreditListHeaderToolbar = () => {
        return (
            <GridContainer
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.screenContentMargin}
            >
                <Typography variant={'body2'} color={'textSecondary'} gutterBottom>
                    Credits
                </Typography>
                <Button variant={'text'} color={'primary'} onClick={handleAddNew}>
                    <Plus /> ADD CREDIT
                </Button>
            </GridContainer>
        );
    };

    return (
        <div className={classes.root}>
            <Formik
                children={(props) => (
                    <AddCreditModal
                        isOpen={modalOpen}
                        closeModal={toggleModal}
                        onCancel={onCancel}
                        socialPrefixes={socialPrefixes}
                        currentUser={currentUser}
                        getFullyQualifiedSocialMediaLink={
                            getFullyQualifiedSocialMediaLink
                        }
                        {...props}
                    />
                )}
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmitModal}
            />

            <ScreenContainer>
                <PageTitleDescription
                    pageTitle={'CREATIVE CREDITS'}
                    description={`Credit artists, producers or directors by linking their ELEVATOR profiles. If a creator does not have a profile you can add their name, email and social links yourself. Added creator credits will be saved for future submissions. Creator credits are displayed publicly on all accepted submissions.`}
                >
                    {submissionCreditsMissingSocialMedia() && (
                        <Callout>
                            Looks like one or more of your credits is missing social
                            media. Give them a shout-out so we can @ them in our posts!
                        </Callout>
                    )}
                </PageTitleDescription>

                {renderCreditListHeaderToolbar()}
                <CreditsList
                    credits={credits}
                    removeCredit={removeCredit}
                    editCredit={editCredit}
                />

                <ActionAreaContainer>
                    <Hidden smDown>
                        <Button
                            variant={'contained'}
                            size={'large'}
                            color={'secondary'}
                            onClick={handleBackPress}
                        >
                            Back
                        </Button>
                        <div className={classes.buttonSpacer} />
                    </Hidden>
                    <Button
                        type={'submit'}
                        variant={'contained'}
                        color={'primary'}
                        fullWidth={mobile}
                        size={'large'}
                        onClick={handleSubmit}
                    >
                        Next
                    </Button>
                </ActionAreaContainer>
            </ScreenContainer>
        </div>
    );
};

CreditsForm.propTypes = {
    classes: PropTypes.object.isRequired,
    nextStep: PropTypes.func.isRequired,
    setField: PropTypes.func.isRequired,
    handleBackPress: PropTypes.func.isRequired,
};

export default withStyles(styles)(CreditsForm);
